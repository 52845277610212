import { NomacData, NomacSelection, NomacState } from '../../interfaces/nomac'
import { typedAction } from '../utils'

const initialState: NomacState = {
  selection: {
    sectionId: '',
    divisionId: '',
    groupId: '',
    classeId: '',
    categoryId: '',
  },
  data: {
    sections: [],
    divisions: [],
    groups: [],
    classesData: [],
    categories: [],
  },
}

// Actions creator
export const updateSelectedNomac = (payload: NomacSelection) =>
  typedAction('NOMAC/UPDATE_SELECTED', payload)
export type UpdateSelectedNomac = typeof updateSelectedNomac

export const fillOutNomacData = (payload: NomacData) =>
  typedAction('NOMAC/FILL_OUT', payload)
export type FillOutNomacData = typeof fillOutNomacData

// Actions type
type NomacAction = ReturnType<UpdateSelectedNomac | FillOutNomacData>

// Reducer
export const NomacReducer = (
  state = initialState,
  action: NomacAction,
): NomacState => {
  switch (action.type) {
    case 'NOMAC/UPDATE_SELECTED':
      return { ...state, selection: { ...action.payload } }
    case 'NOMAC/FILL_OUT':
      return { ...state, data: { ...action.payload } }
    default:
      return state
  }
}
