import { INIT_SUB_LOCALITIES } from '../../constants/address'
import { SubmitFormAddressEnum } from '../../enum/submitForm'
import {
  AddressOnlyState,
  LoadAddressArgs,
  SetSubAddressesArgs,
} from '../../interfaces/address'
import { SelectItem } from '../../interfaces/common'
import { SubmitFormAddressArgs } from '../../interfaces/submitForm'
import { typedAction } from '../utils'

const initialState: AddressOnlyState = {
  provinces: [],
  Identity: { ...INIT_SUB_LOCALITIES },
  Company: { ...INIT_SUB_LOCALITIES },
}

// Actions creator
export const getProvinceSaga = () => typedAction('ADDRESS/GET_PROVINCE_SAGA')
export type GetProvinceSaga = typeof getProvinceSaga

export const setProvinces = (provinces: SelectItem[]) =>
  typedAction('ADDRESS/SET_PROVINCE', provinces)
type SetProvinces = typeof setProvinces

export const addressChangeSaga = (payload: SubmitFormAddressArgs) =>
  typedAction('ADDRESS/CHANGE', payload)
export type AddressChangeSaga = typeof addressChangeSaga

export const setSubAddresses = (payload: SetSubAddressesArgs) =>
  typedAction('ADDRESS/SET_SUB_ADDRESSES', payload)
type SetSubAddresses = typeof setSubAddresses

export const loadAddressSaga = (payload: LoadAddressArgs) =>
  typedAction('ADDRESS/LOAD', payload)
export type LoadAddressSaga = typeof loadAddressSaga

export const loadQuartierSaga = (payload: LoadAddressArgs) =>
  typedAction('ADDRESS/LOAD_QUARTIER', payload)
export type LoadQuartierSaga = typeof loadQuartierSaga

// Actions type
type AddressAction = ReturnType<SetProvinces | SetSubAddresses>

// Reducer
export const AddressReducer = (
  state = initialState,
  action: AddressAction,
): AddressOnlyState => {
  switch (action.type) {
    case 'ADDRESS/SET_PROVINCE':
      return { ...state, provinces: [...action.payload] }

    case 'ADDRESS/SET_SUB_ADDRESSES':
      const { subAddressToChange, fieldName, fieldData } = action.payload
      if (subAddressToChange === SubmitFormAddressEnum.IDENTITY) {
        return {
          ...state,
          Identity: { ...state.Identity, [fieldName]: [...fieldData] },
        }
      } else if (subAddressToChange === SubmitFormAddressEnum.COMPANY) {
        return {
          ...state,
          Company: { ...state.Company, [fieldName]: [...fieldData] },
        }
      } else {
        return state
      }

    default:
      return state
  }
}
