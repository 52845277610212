import axios, { AxiosResponse } from 'axios'
import { API_SERVER_URL } from '../../constants/common'
// import { MessageErrorEnum } from '../../enum/message'
const COMMON_ROOT = 'gateway/common'

export const getWorldTime = (): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(`${API_SERVER_URL}/${COMMON_ROOT}/datetime`)
      .then(res => {
        if (res.data && res.data.date) {
          resolve(res.data.date)
        } else {
          resolve(new Date().toString() as any)
        }
      })
      .catch(e => {
        // reject(new Error(MessageErrorEnum.NETWORK_ERROR))
        resolve(new Date().toString() as any)
      }),
  )

export const getCurrentIP = (): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(`https://api.ipify.org/?format=json`)
      .then(res => {
        if (res.data && res.data.ip) {
          resolve(res.data.ip)
        } else {
          resolve('127.0.0.1' as any) // Prevent bug if api.ipify.org doesn't work
        }
      })
      .catch(e => {
        resolve('127.0.0.1' as any) // Prevent bug if api.ipify.org doesn't work
      }),
  )
