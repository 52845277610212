import React, { useState, useEffect } from 'react'
import ArrowUpIcon from '@material-ui/icons/ArrowUpward'
import { IconButton, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { showScroll: boolean }) => ({
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: props.showScroll ? 'flex' : 'none',
    // backgroundColor: '#405A9F',
    color: 'black',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    zIndex: 1000,
  }),
}))

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false)
  const classes = useStyles({ showScroll })

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop)
    return function cleanup() {
      window.removeEventListener('scroll', checkScrollTop)
    }
  })

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <IconButton className={classes.root} onClick={scrollTop}>
      <ArrowUpIcon />
    </IconButton>
  )
}

export default ScrollToTop
