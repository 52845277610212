import { SelectItem } from '../interfaces/common'
import {
  AddressState,
  CompanyIdentityState,
  CompanySiegeState,
  IdentityState,
  IdsForUpdate,
} from '../interfaces/submitForm'

export const INIT_ADDRESS: AddressState = {
  province: '',
  region: '',
  district: '',
  commune: '',
  arrondissement: '',
  quartier: '',
  localAddress: '',
}

export const INIT_IDENTITY: IdentityState = {
  name: '',
  firstName: '',
  mail: '',
  phonenumber: '+261',
  tel2: '',
  address: { ...INIT_ADDRESS },
  role: '',
  companyProxy: '',
}

export const INIT_COMPANY_IDENTITY: CompanyIdentityState = {
  name: '',
  statusForm: '',
  nomac: '',
  socialObject: '',
  isImportActivity: '',
  isIndustrialActivity: '',
  isWholesalerActivity: '',
  isOtherActivity: '',
  capital: '',
  statusDate: new Date().toString(),
  impositionChoice: '',
  physicalSharholderNb: '',
  logicalSharholderNb: '',
  leadersNb: '',
  tva: '',
}

export const INIT_COMPANY_SIEGE: CompanySiegeState = {
  address: { ...INIT_ADDRESS },
  contractType: '',
  bailleurType: '',
  bailDuration: '',
  bailMonthlyAmount: '',
}

export const INIT_IDS_FOR_UPDATE: IdsForUpdate = {
  personne: {
    idPersonne: 0,
    idAdresse: 0,
  },
  societe: {
    idSociete: 0,
    idAdresse: 0,
    idSiegeSocial: 0,
  },
}

export const IMPOSITION_CHOICE_ITEMS: SelectItem[] = [
  {
    label: '',
    value: '',
  },
  {
    label: 'IR - Impôt sur le Revenu',
    value: '1',
  },
  {
    label: 'IS - Impôt Synthétique',
    value: '0',
  },
]

export const LINK_ERROR_CODE = '-1'

export const ID_FOLDER_UPDATE_STATUS = 2

export const OTHER_DOCUMENT_TYPE_ID = '33'

export const LEGAL_STATUS_DICT: { [id: string]: string } = {
  '7': 'GIE',
  '2': 'EI',
  '1': 'SARL',
  '5': 'SCS',
  '4': 'SNC',
  '6': 'SC',
  '8': 'SDET',
  '11': 'SARL/SARLU',
  '12': 'SA/SAU',
  '10': 'SARLU',
  '13': 'SA/AG',
  '14': 'SA/CA',
  '3': 'SA',
  '9': 'SAU',
}
