import { makeStyles, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Img from 'gatsby-image'
import React, { FC, useState } from 'react'
import Carousel from 'react-simply-carousel'
import useInterval from '../../../hooks/utils/useInterval'
import { ImageAdvertisingItem } from '../../../interfaces/advertising'

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    // width: '320px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

// NOTE: Children must be more than one element to prevent bug

interface PubCarouselProps {
  width: number
  height: number
  imagesData: ImageAdvertisingItem[]
  delay?: number
  speed?: number
}

const PubCarousel: FC<PubCarouselProps> = props => {
  const { width, height, imagesData } = props
  const delay = props.delay || 6000
  const speed = props.speed || 500
  const interval = delay + speed + 1000

  const classes = useStyles()

  // const [activeSlide, setActiveSlide] = useState(1) // Use useInterval instead because this pakage bug while clicking on a slide
  const [activeSlide, setActiveSlide] = useState(0)

  // Workaround because auto play does not work very well
  // Workaround not needed anymore while using useInterval
  // useEffect(() => {
  //   setActiveSlide(0)
  // }, [])

  // Use useInterval instead because this pakage bug while clicking on a slide
  useInterval(() => {
    if (activeSlide < imagesData.length - 1) {
      setActiveSlide(activeSlide => activeSlide + 1)
    } else {
      setActiveSlide(0)
    }
  }, interval)

  const handleGoTo = (link: string) => () => {
    window.open(link)
  }

  return (
    <Box display="flex" justifyContent="center">
      {imagesData.length > 1 ? (
        <Carousel
          autoplay={true}
          containerProps={{
            style: {
              width,
              height,
              justifyContent: 'center',
            },
          }}
          activeSlideIndex={activeSlide}
          // onRequestChange={setActiveSlide} // Use useInterval instead because this pakage bug while clicking on a slide
          forwardBtnProps={{
            children: '>',
            style: {
              display: 'none', // Tricks to hide navigation
            },
          }}
          backwardBtnProps={{
            children: '<',
            style: {
              display: 'none', // Tricks to hide navigation
            },
          }}
          itemsToShow={1}
          speed={speed}
          delay={delay}
        >
          {imagesData.map((img, i) => (
            <span
              key={`banner-${img.link}-${i}`}
              className={classes.image}
              onClick={handleGoTo(img.link)}
            >
              <Img
                fixed={img.dataImage.childImageSharp.fixed}
                alt={`banner ${img.link}`}
              />
            </span>
          ))}
        </Carousel>
      ) : (
        <span
          className={classes.image}
          onClick={handleGoTo(imagesData[0].link)}
        >
          <Img
            key={`banner-${imagesData[0].link}`}
            fixed={imagesData[0].dataImage.childImageSharp.fixed}
            alt={`banner ${imagesData[0].link}`}
          />
        </span>
      )}
    </Box>
  )
}

export default PubCarousel
