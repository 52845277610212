import format from 'date-fns/format'
import { MailLinkTypeEnum } from '../enum/mail'
import { FolderStatusEnum, LegalStatusEnum } from '../enum/submitForm'
import { ActivityCategories } from '../hooks/nomac/useActivityCategories'
import { ActivityClasses } from '../hooks/nomac/useActivityClasses'
import { ActivityGroups } from '../hooks/nomac/useActivityGroups'
import { LegalStatusData } from '../hooks/useAllLegalStatus'
import { OptionItem, SelectItem } from '../interfaces/common'
import { LegalStatusFormData } from '../interfaces/formData'
import {
  CompanyIdentityState,
  CompanyIdentityToSend,
  CompanySiegeState,
  CompanySiegeToSend,
  IdentityState,
  IdentityToSend,
  IdsForPerson,
  IdsForSociete,
  PersonToSend,
  SiegeToSend,
  Step4Fields,
  SubmitFormState,
  SubmitFormToSend,
  UploadedDocumentsInfo,
  UserFolderInfo,
} from '../interfaces/submitForm'
import { formatAddressToSend, isAddressFieldsValid } from './address'
import {
  checkStringLength,
  formatDateFromBack,
  formatNumber,
  generateFolderNumber,
  getInternationalizedValue,
  getNumberId,
  stringToNumber,
} from './common'
import { getActivityCode } from './nomac'
import { isValidEmail, isValidPhoneNumber } from './validation'

export const getNomacToSearch = (
  groups: ActivityGroups[],
  classes: ActivityClasses[],
  categories: ActivityCategories[],
  language = 'fr',
): SelectItem[] => {
  const nomacsItem: SelectItem[] = []

  groups.forEach(g => {
    const intlLabel = getInternationalizedValue(
      g,
      'libelleGroupeActivite',
      language,
    )
    const groupCode = getActivityCode(g.id)

    nomacsItem.push({
      label: `${groupCode} - ${intlLabel}`,
      value: groupCode,
    })
  })

  classes.forEach(c => {
    const intlLabel = getInternationalizedValue(
      c,
      'libelleClasseActivite',
      language,
    )
    const classCode = getActivityCode(c.id)

    nomacsItem.push({
      label: `${classCode} - ${intlLabel}`,
      value: classCode,
    })
  })

  categories.forEach(c => {
    const intlLabel = getInternationalizedValue(
      c,
      'descriptivesCategorie',
      language,
    )
    const categoryCode = getActivityCode(c.id)

    nomacsItem.push({
      label: `${categoryCode} - ${intlLabel}`,
      value: categoryCode,
    })
  })

  return nomacsItem
}

export const isAllIdentityFieldsOK = (fields: IdentityState): boolean => {
  const {
    name,
    firstName,
    address,
    companyProxy,
    mail,
    phonenumber,
    role,
    tel2,
  } = fields

  // Company proxy is not mandatory, but if exist: beetween 3 and 250 chars
  const isCompanyProxyOk =
    companyProxy.length > 0 ? checkStringLength(companyProxy) : true

  return checkStringLength(name) &&
    checkStringLength(firstName) &&
    isAddressFieldsValid(address) &&
    isCompanyProxyOk &&
    isValidEmail(mail) &&
    isValidPhoneNumber(phonenumber) &&
    tel2
    ? isValidPhoneNumber(tel2)
    : true && !!role
}

export const isCompanySiegeFieldsOK = (fields: CompanySiegeState): boolean => {
  const {
    address,
    bailDuration,
    bailMonthlyAmount,
    bailleurType,
    contractType,
  } = fields

  return (
    isAddressFieldsValid(address) &&
    !!bailleurType &&
    !!contractType &&
    !!bailDuration.length &&
    !!bailMonthlyAmount.length
  )
}

export const isNbSharholderOK = (
  physical: string,
  logical: string,
  statusForm: string,
): boolean => {
  if (
    physical.replace(/ /g, '').length === 0 ||
    logical.replace(/ /g, '').length === 0
  ) {
    return false
  }
  if (isNaN(+physical.replace(/ /g, '')) || isNaN(+logical.replace(/ /g, ''))) {
    return false
  }
  if (
    statusForm === LegalStatusEnum.SAU ||
    statusForm === LegalStatusEnum.SARLU
  ) {
    const nbPhysical = +physical.replace(/ /g, '')
    const nbLogical = +logical.replace(/ /g, '')
    return nbPhysical + nbLogical === 1
  }
  return true
}

export const isCompanyIdentityFieldsOK = (
  fields: CompanyIdentityState,
): boolean => {
  const {
    capital,
    impositionChoice,
    isImportActivity,
    isIndustrialActivity,
    isOtherActivity,
    isWholesalerActivity,
    leadersNb,
    logicalSharholderNb,
    name,
    nomac,
    physicalSharholderNb,
    socialObject,
    statusDate,
    statusForm,
    tva,
  } = fields

  return (
    !!statusForm &&
    checkStringLength(name) &&
    !!nomac &&
    checkStringLength(socialObject, 0, 2500) &&
    !!isImportActivity &&
    !!isIndustrialActivity &&
    !!isWholesalerActivity &&
    !!isOtherActivity &&
    !!capital.length &&
    new Date(statusDate) <= new Date() &&
    !!impositionChoice &&
    isNbSharholderOK(physicalSharholderNb, logicalSharholderNb, statusForm) &&
    !!logicalSharholderNb.length &&
    !!leadersNb.length &&
    !!tva.length
  )
}

export const getStatusFormItem = (
  all: LegalStatusData[],
  language = 'fr',
): SelectItem[] => {
  // First filter status form: don't include one which has slash '/'
  const filtred = all.filter(
    l => !l.STATUS.includes('/') && l.STATUS !== 'SA' && l.STATUS !== 'EI',
  )
  const results = filtred.map(f => {
    const label = getInternationalizedValue(f.Status, 'legal_status', language)
    const statusId = getNumberId(f.id)
    return { label: `${f.STATUS} - ${label}`, value: `${statusId}` }
  })
  results.unshift({ label: '', value: '' })
  return results
}

export const getRolesOption = (data: any[]): OptionItem[] => {
  const results: OptionItem[] = []
  const resultMap = new Map<string, OptionItem[]>()

  data.forEach(d => {
    const { id_parent, id_role, libelle_role } = d
    // If current data is a parent, save it immediately
    if (id_parent === id_role) {
      results.push({ label: libelle_role, value: id_role, children: [] })
    } else {
      // Save results temporary into a map
      const currentOption: OptionItem = {
        label: libelle_role,
        value: id_role,
        children: [],
      }
      const parentOption = resultMap.get(id_parent)
      if (parentOption) {
        resultMap.set(id_parent, [...parentOption, currentOption])
      } else {
        resultMap.set(id_parent, [currentOption])
      }
    }
  })

  return results.map(r => {
    const { label, value } = r
    const currentChildren = resultMap.get(r.value)
    if (currentChildren) {
      return { label, value, children: currentChildren }
    } else {
      return { label, value, children: [] }
    }
  })
}

export const getTypeBailleurItems = (data: any[]): SelectItem[] => {
  const results = data.map(d => ({
    label: d.nomTypeBailleur,
    value: d.idTypeBailleur,
  }))
  results.unshift({ label: '', value: '' })
  return results
}

export const getTypeContractItems = (data: any[]): SelectItem[] => {
  const results = data.map(d => ({
    label: d.nomTypeContrat,
    value: d.idTypeContrat,
  }))
  results.unshift({ label: '', value: '' })
  return results
}

const formatPersonToSend = (
  identity: IdentityState,
): PersonToSend | undefined => {
  const {
    name,
    firstName,
    mail,
    phonenumber,
    role,
    companyProxy,
    tel2,
  } = identity

  // Avoid sending 0 if it is an empty string ''
  if (!role) {
    return undefined
  }
  return {
    nom: name,
    prenom: firstName,
    e_mail: mail,
    tel: phonenumber,
    tel2,
    societe_mandataire: companyProxy,
    idRole: +role,
  }
}

export const formatIdentityToSend = (
  identity: IdentityState,
): IdentityToSend | undefined => {
  const adresse = formatAddressToSend(identity.address)
  const personne = formatPersonToSend(identity)

  if (adresse && personne) {
    return {
      adresse,
      personne,
    }
  }

  return undefined
}

export const formatCompanyIdentityToSend = (
  companyIdentity: CompanyIdentityState,
  legalStatusList: LegalStatusFormData[],
  currentTimestamp: number,
): CompanyIdentityToSend | undefined => {
  const {
    name,
    statusDate,
    capital,
    impositionChoice,
    isImportActivity,
    isIndustrialActivity,
    isOtherActivity,
    isWholesalerActivity,
    leadersNb,
    logicalSharholderNb,
    nomac,
    physicalSharholderNb,
    socialObject,
    statusForm,
    tva,
  } = companyIdentity
  const legalStatus = legalStatusList.filter(l => l.id === +statusForm) // Find STATUS acronym
  const status = legalStatus.length > 0 ? legalStatus[0].status : ''
  // Avoid sending 0 if it is an empty string ''
  if (
    !isImportActivity ||
    !isIndustrialActivity ||
    !isWholesalerActivity ||
    !isOtherActivity ||
    !impositionChoice
  ) {
    return undefined
  }

  return {
    denominationSocial: name,
    activitePrincipal: nomac,
    formeJuridique: status,
    idFormeJuridique: +statusForm,
    dateStatut: format(new Date(statusDate), 'yyyy-MM-dd'),
    capital: +capital.replace(/ /g, ''),
    activiteImportExport: +isImportActivity,
    activiteIndustrielCollecteur: +isIndustrialActivity,
    activiteGrossiste: +isWholesalerActivity,
    autreActiviteReglemente: +isOtherActivity,
    choixImposition: +impositionChoice,
    objetSocial: socialObject,
    nombreAssociePersPhysique: +physicalSharholderNb.replace(/ /g, ''),
    nombreAssociePersMorale: +logicalSharholderNb.replace(/ /g, ''),
    nombreDirigeant: +leadersNb.replace(/ /g, ''),
    numeroDossier: generateFolderNumber(currentTimestamp),
    tva: +tva,
  }
}

const formatSiegeToSend = (
  company: CompanySiegeState,
): SiegeToSend | undefined => {
  const {
    contractType,
    bailleurType,
    bailDuration,
    bailMonthlyAmount,
  } = company

  // Avoid sending 0 if it is an empty string ''
  if (!contractType || !bailleurType) {
    return undefined
  }
  return {
    dureebail: +bailDuration.replace(/ /g, ''),
    montantBail: +bailMonthlyAmount.replace(/ /g, ''),
    idTypeBailleur: +bailleurType,
    idTypeContrat: +contractType,
  }
}

export const formatCompanySiegeToSend = (
  company: CompanySiegeState,
): CompanySiegeToSend | undefined => {
  const adresse = formatAddressToSend(company.address)
  const siege = formatSiegeToSend(company)

  if (adresse && siege) {
    return {
      adresse,
      siege,
    }
  }

  return undefined
}

export const formatSubmitFormToSend = (
  data: SubmitFormState,
  legalStatusList: LegalStatusFormData[],
  currentTimestamp: number,
  step4: Step4Fields,
): SubmitFormToSend | undefined => {
  const { identity, companyIdentity, companySiege } = data
  const step1 = formatIdentityToSend(identity)
  const step2 = formatCompanyIdentityToSend(
    companyIdentity,
    legalStatusList,
    currentTimestamp,
  )
  const step3 = formatCompanySiegeToSend(companySiege)
  if (step1 && step2 && step3) {
    return {
      step1,
      step2,
      step3,
      step4,
    }
  }
  return undefined
}

// Format person data from back according to store format
export const formatReceivedPersonData = (
  personPayload: any,
): IdentityState | undefined => {
  const identityData =
    (personPayload.person &&
      personPayload.person.personnes &&
      personPayload.person.personnes.personne) ||
    undefined
  const addressData =
    (personPayload.personAdress &&
      personPayload.personAdress.adresses &&
      personPayload.personAdress.adresses.adresse) ||
    undefined

  if (identityData && addressData) {
    return {
      name: identityData.nom || '',
      firstName: identityData.prenom || '',
      mail: identityData.e_mail || '',
      phonenumber: identityData.tel || '',
      tel2: identityData.tel2 || '', // TO RE-RECHECK AFTER BACK DONE
      role: identityData.idrole || '',
      companyProxy: identityData.societe_mandataire || '',
      address: {
        province: addressData.idProvince || '',
        region: addressData.idRegion || '',
        district: addressData.idDistrict || '',
        commune: addressData.idCommune || '',
        arrondissement: addressData.idArrondissement || '',
        quartier: addressData.idFokontany || '',
        localAddress: addressData.adresse || '',
      },
    }
  }
  return undefined
}

// Format company identity data from back according to store format
export const formatReceivedCompanyIdentityData = (
  societyPayload: any,
): CompanyIdentityState | undefined => {
  const identityData =
    (societyPayload &&
      societyPayload.society &&
      societyPayload.society.societies &&
      societyPayload.society.societies.society) ||
    undefined
  if (identityData) {
    return {
      name: identityData.denominationSocial || '',
      statusForm: identityData.formeJuridique || '',
      nomac: identityData.activitePrincipal || '',
      socialObject: identityData.objetSocial || '',
      isImportActivity: identityData.activiteImportExport || '',
      isIndustrialActivity: identityData.activiteIndustrielCollecteur || '',
      isWholesalerActivity: identityData.activiteGrossiste || '',
      isOtherActivity: identityData.autreActiviteReglemente || '',
      capital: identityData.capital
        ? `${formatNumber(+identityData.capital)}`
        : '0', // Prevent decimal
      statusDate: formatDateFromBack(identityData.dateStatut),
      impositionChoice: identityData.choixImposition || '0',
      physicalSharholderNb:
        `${formatNumber(+identityData.nombreAssociePersPhysique)}` || '0',
      logicalSharholderNb:
        `${formatNumber(+identityData.nombreAssociePersMorale)}` || '0',
      leadersNb: `${formatNumber(+identityData.nombreDirigeant)}` || '0',
      tva: identityData.tva || '', // TO RE-RECHECK AFTER BACK DONE
    }
  }
  return undefined
}

// Format folder data from back according to store format
export const formatReceivedFolderyData = (
  folderPayload: any,
  societyPayload: any,
): UserFolderInfo | undefined => {
  const identityData =
    (societyPayload &&
      societyPayload.society &&
      societyPayload.society.societies &&
      societyPayload.society.societies.society) ||
    undefined
  const folderData =
    (folderPayload &&
      folderPayload.dossier &&
      folderPayload.dossier.dossierInfo &&
      folderPayload.dossier.dossierInfo.iformation) ||
    undefined
  if (identityData) {
    return {
      id: stringToNumber(folderData.idDossier),
      number: folderData.numeroDossier || '',
      idSociete: stringToNumber(identityData.idSociete),
      idStatutDossier: stringToNumber(folderData.idStatutDossier) || 0,
    }
  }
  return undefined
}

// Format company siege data from back according to store format
export const formatReceivedCompanySiegeData = (
  societyPayload: any,
): CompanySiegeState | undefined => {
  const siegeData =
    (societyPayload &&
      societyPayload.siegeSociety &&
      societyPayload.siegeSociety.sieges &&
      societyPayload.siegeSociety.sieges.siege) ||
    undefined
  const addressData =
    (societyPayload &&
      societyPayload.siegeSocietyAdress &&
      societyPayload.siegeSocietyAdress.adresses &&
      societyPayload.siegeSocietyAdress.adresses.adresse) ||
    undefined

  if (siegeData && addressData) {
    return {
      contractType: siegeData.idTypeContrat || '',
      bailleurType: siegeData.idTypeBailleur || '',
      bailDuration: siegeData.dureebail
        ? `${formatNumber(+siegeData.dureebail)}`
        : '0', // Prevent decimal
      bailMonthlyAmount: siegeData.montantBail
        ? `${formatNumber(+siegeData.montantBail)}`
        : '0', // Prevent decimal
      address: {
        province: addressData.idProvince || '',
        region: addressData.idRegion || '',
        district: addressData.idDistrict || '',
        commune: addressData.idCommune || '',
        arrondissement: addressData.idArrondissement || '',
        quartier: addressData.idFokontany || '',
        localAddress: addressData.adresse || '',
      },
    }
  }
  return undefined
}

// Format person and its address ids data from back for update
export const getPersonIdsForUpdate = (
  personPayload: any,
): IdsForPerson | undefined => {
  const identityData =
    (personPayload.person &&
      personPayload.person.personnes &&
      personPayload.person.personnes.personne) ||
    undefined
  const addressData =
    (personPayload.personAdress &&
      personPayload.personAdress.adresses &&
      personPayload.personAdress.adresses.adresse) ||
    undefined

  if (identityData && addressData) {
    return {
      idPersonne: stringToNumber(identityData.idPersonne),
      idAdresse: stringToNumber(identityData.idadresse),
    }
  }
  return undefined
}

// Format company identity id, its siege and address ids data from back for update
export const getCompanyIdsForUpdate = (
  societyPayload: any,
): IdsForSociete | undefined => {
  const identityData =
    (societyPayload &&
      societyPayload.society &&
      societyPayload.society.societies &&
      societyPayload.society.societies.society) ||
    undefined
  const siegeData =
    (societyPayload &&
      societyPayload.siegeSociety &&
      societyPayload.siegeSociety.sieges &&
      societyPayload.siegeSociety.sieges.siege) ||
    undefined
  const addressData =
    (societyPayload &&
      societyPayload.siegeSocietyAdress &&
      societyPayload.siegeSocietyAdress.adresses &&
      societyPayload.siegeSocietyAdress.adresses.adresse) ||
    undefined

  if (identityData && siegeData && addressData) {
    return {
      idSociete: stringToNumber(identityData.idSociete),
      idSiegeSocial: stringToNumber(siegeData.idSiege),
      idAdresse: stringToNumber(addressData.idAdresse),
    }
  }
  return undefined
}

export const getFormattedUploadedDocuments = (
  data: any[],
): UploadedDocumentsInfo[] =>
  data.map((d: any) => {
    const currentDoc: UploadedDocumentsInfo = {
      nomDocument: d.nomDocument || '',
      idTypeDocument: stringToNumber(d.idTypeDocument),
      path: d.path || '',
      idDossier: stringToNumber(d.idDossier),
      nbDeposer: stringToNumber(d.nbDeposer),
      idDocument: stringToNumber(d.idDocument),
    }
    return currentDoc
  })

// Format uploaded documents list data from back according to store format
export const formatUploadedDocumentsData = (
  folderPayload: any,
): UploadedDocumentsInfo[] => {
  const documentsList =
    (folderPayload &&
      folderPayload.document &&
      folderPayload.document.documents &&
      folderPayload.document.documents.document) ||
    undefined
  if (documentsList) {
    const docList = getFormattedUploadedDocuments(documentsList)
    return docList
  }
  return []
}

export const getItemsByData = (dataItem: SelectItem[]): SelectItem[] => {
  const results = dataItem.map(d => ({
    label: d.label,
    value: d.value,
  }))
  results.unshift({ label: '', value: '' })
  return results
}

export const isReadOnlyForms = (
  linkType: string,
  folderStatus: number,
): boolean => {
  return (
    linkType === MailLinkTypeEnum.READ ||
    folderStatus === FolderStatusEnum.RENDEZ_VOUS_OK ||
    folderStatus === FolderStatusEnum.DONE ||
    folderStatus === FolderStatusEnum.TRAIT_OK
  )
}
