import { all, fork } from 'redux-saga/effects'
import {
  addressChangeWatcher,
  getProvinceWatcher,
  loadAddressWatcher,
  loadQuartierWatcher,
} from './address'
import {
  getPersonRoleWatcher,
  getSubmitFormDocListWatcher,
  getTypeBailleurWatcher,
  getTypeContractWatcher,
  getAllAddressesWatcher,
  getAllArrondissementWatcher,
} from './formData'
import { sendMailWatcher } from './mail'
import {
  getStatisticGraphWatcher,
  getStatisticIntervaleWatcher,
  getStatisticOverviewWatcher,
} from './statistic'
import {
  sendSubmitFormAllDocumentWatcher,
  sendSubmitFormDocumentWatcher,
  sendSubmitFormWatcher,
  submitFormSaveLinkWatcher,
  sendMailAndSaveLinkWatcher,
  checkLinkFromMailWatcher,
  getInformationByLinkWatcher,
  updatePersonWatcher,
  updateCompanyWatcher,
  updateCompanySiegeWatcher,
  loadUploadedDocumentsIntoStoreWatcher,
  handleNoDocumentWatcher,
} from './submitForm'

const rootSaga = function* root() {
  yield all([
    // Address
    fork(getProvinceWatcher),
    fork(addressChangeWatcher),
    fork(loadAddressWatcher),
    fork(loadQuartierWatcher),
    // Form data
    fork(getPersonRoleWatcher),
    fork(getTypeBailleurWatcher),
    fork(getTypeContractWatcher),
    fork(getSubmitFormDocListWatcher),
    fork(getAllAddressesWatcher),
    fork(getAllArrondissementWatcher),
    // Submit form
    fork(sendSubmitFormWatcher),
    fork(sendSubmitFormDocumentWatcher),
    fork(sendSubmitFormAllDocumentWatcher),
    fork(submitFormSaveLinkWatcher),
    fork(sendMailAndSaveLinkWatcher),
    fork(checkLinkFromMailWatcher),
    fork(getInformationByLinkWatcher),
    fork(updatePersonWatcher),
    fork(updateCompanyWatcher),
    fork(updateCompanySiegeWatcher),
    fork(loadUploadedDocumentsIntoStoreWatcher),
    fork(handleNoDocumentWatcher),
    // Mail
    fork(sendMailWatcher),
    // Statistic
    fork(getStatisticOverviewWatcher),
    fork(getStatisticIntervaleWatcher),
    fork(getStatisticGraphWatcher),
  ])
}

export default rootSaga
