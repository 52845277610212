import { Box, makeStyles, Theme, Toolbar } from '@material-ui/core'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EDBMLogos from '../../../components/common/logos/EDBMLogos'
import SimpleLink from '../../../components/customLinks/simpleLink'
import SiteTitle from '../../../components/siteTitle'
import CONFIG from '../../../config'
import { MenuLinkItem } from '../../../interfaces/common'
import { FormProps } from '../../../interfaces/form'
// import { switchLanguage } from '../../../redux/modules/appModule'
import { switchLanguage } from '../../../redux/modules/app'
import { RootState } from '../../../redux/store'

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1140,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      margin: `0px ${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    },
    [theme.breakpoints.up('md')]: {
      margin: `0px ${theme.spacing(8)}px 0px ${theme.spacing(8)}px`,
    },
    [theme.breakpoints.up('lg')]: {
      margin: `0px ${theme.spacing(16)}px 0px ${theme.spacing(16)}px`,
    },
  },

  searchbarContainer: {
    marginRight: theme.spacing(2),
    width: 200,
  },
  linkButtonLogin: {
    padding: theme.spacing(1.5),
    color: '#384252',
    border: `solid 1px #D1D5DB`,
    borderRadius: 6,
    textDecoration: 'none',
    fontSize: 16,
    '&:hover': {
      backgroundColor: '#eee',
      cursor: 'pointer',
    },
    width: 122,
    textAlign: 'center',
  },
  linkButtonSignUp: {
    padding: theme.spacing(1.5),
    color: '#F9FAFB',
    backgroundColor: '#179D75',
    borderRadius: 6,
    textDecoration: 'none',
    fontSize: 16,
    marginLeft: 10,
    width: 122,
    textAlign: 'center',
  },
  divider: {
    width: 0,
    height: 44,
    borderRight: '1px solid #D1D5DB',
    margin: '0 20px',
  },
}))

interface DesktopHeaderProps {
  siteName: string
  menuItems: MenuLinkItem[]
  connexion: string
  signup: string
}

const DesktopHeader: FC<DesktopHeaderProps> = props => {
  const { siteName, menuItems, connexion, signup } = props

  const {
    toolbar,
    container,
    linkButtonLogin,
    linkButtonSignUp,
    divider,
  } = useStyles()
  const dispatch = useDispatch()
  const { language } = useSelector((state: RootState) => state.app)

  const handleLanguageChange = (payload: FormProps) => {
    dispatch(switchLanguage(`${payload.value}`))
  }

  return (
    <Toolbar className={toolbar}>
      <Box className={container}>
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <SiteTitle label={siteName} />
          </Box>
          <EDBMLogos />
        </Box>

        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            {menuItems.map((item, index) => (
              <Box key={`head-menu-${index}`} marginX={1}>
                <SimpleLink item={item} isBold={true} />
              </Box>
            ))}
          </Box>
          <div className={divider} />
          {/* <Box className={searchbarContainer}><SearchBar /></Box> */}
          <a
            href={`${CONFIG.clientSpaceUrl}/login`}
            target="blank"
            className={linkButtonLogin}
          >
            {connexion}
          </a>

          <a
            href={`${CONFIG.clientSpaceUrl}/sign-up`}
            target="blank"
            className={linkButtonSignUp}
          >
            {signup}
          </a>
          {/* <CustomSelect
            name="language"
            value={language}
            items={LANGUAGES_ITEM}
            handleChange={handleLanguageChange}
            isSmall={true}
          /> */}
        </Box>
      </Box>
    </Toolbar>
  )
}

export default DesktopHeader
