import {
  FolderStatNumber,
  StatisticAverage,
  StatisticIntervalItem,
  StatisticState,
} from '../interfaces/statistic'

export const INIT_FOLDER_STAT_NUMBER: FolderStatNumber = {
  newFolder: 0,
  inProgress: 0,
  traitOK: 0,
  traitKO: 0,
  immatInProgress: 0,
  rdvOK: 0,
  updated: 0,
  done: 0,
}

const INIT_STATISTIC_AVERAGE: StatisticAverage = {
  avgDelay: 0,
  minDelay: 0,
  maxDelay: 0,
}

const INIT_STATISTIC_INTERVAL_ITEM: StatisticIntervalItem = {
  delay: 0,
  total: 0,
}

export const INIT_STATISTIC_STATE: StatisticState = {
  overview: {
    approval: {
      ...INIT_STATISTIC_AVERAGE,
    },
    createdCompany: 0,
    immatriculation: {
      ...INIT_STATISTIC_AVERAGE,
    },
    inProgress: 0,
    submissionFolder: 0,
  },
  interval: {
    intervals: {
      admissible: { ...INIT_STATISTIC_INTERVAL_ITEM },
      immatriculation: { ...INIT_STATISTIC_INTERVAL_ITEM },
      inProgress: { ...INIT_STATISTIC_INTERVAL_ITEM },
      pendingPayment: { ...INIT_STATISTIC_INTERVAL_ITEM },
      ready: { ...INIT_STATISTIC_INTERVAL_ITEM },
      submission: { ...INIT_STATISTIC_INTERVAL_ITEM },
    },
    totalDelay: 0,
  },
  graph: [],
}
