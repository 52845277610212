import { call, put, takeEvery } from 'redux-saga/effects'
import { MessageTypeEnum } from '../../enum/message'
import API from '../api'
import { displayGeneralLoading } from '../modules/loading'
import { displayMessage } from '../modules/message'
import {
  setStatisticGraph,
  setStatisticInterval,
  setStatisticOverview,
} from '../modules/statistic'

function* getStatisticOverviewSE(): any {
  try {
    yield put(displayGeneralLoading(true))
    const data = yield call(API.statistic.getStatisticOverviewApi)
    yield put(setStatisticOverview(data))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* getStatisticOverviewWatcher() {
  yield takeEvery('STATISTIC/GET_OVERVIEW_SAGA', getStatisticOverviewSE)
}

function* getStatisticIntervaleSE(): any {
  try {
    yield put(displayGeneralLoading(true))
    const data = yield call(API.statistic.getStatisticIntervalApi)
    yield put(setStatisticInterval(data))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* getStatisticIntervaleWatcher() {
  yield takeEvery('STATISTIC/GET_INTERVAL_SAGA', getStatisticIntervaleSE)
}

function* getStatisticGraphApiSE(): any {
  try {
    yield put(displayGeneralLoading(true))
    const data = yield call(API.statistic.getStatisticGraphApi)
    yield put(setStatisticGraph(data))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* getStatisticGraphWatcher() {
  yield takeEvery('STATISTIC/GET_GRAPH_SAGA', getStatisticGraphApiSE)
}
