import CONFIG from '../config'

export const MAIL_LINK_ROOT = `${CONFIG.siteUrl}/checkSubmitLink`

export const FORBIDEN_MAIL = [
  'mailinator.com',
  'binkmail.com',
  'suremail.info',
  'safetymail.info',
  'mailnesia.com',
  'vipmailonly.info',
  'mailnull.com',
  'mailsac.com',
  'mailshiv.com',
  'mailtemp.net',
  'fesk.io',
  'jetable.org',
  'jetable.net',
  'jetable.fr.nf',
  'filzmail.com',
  'ephemail.net',
  'trashmail.net',
  'spamcorptastic.com',
  'yopmail.com',
  'yopmail.fr',
  'yopmail.net',
  'yopweb.com',
  'solidblacktattoo.cn',
  'spam.dontassrape.us',
  'spam.h0lger.de',
  'spam.hack.se',
  'spam.mafia-server.net',
  'spam.tagnard.net',
  'spam.w00ttech.com',
  'spamout.jassi.info',
  'thegaybay.com',
  'trash-can.eu',
  'tyros2.cn',
  'vuilnisbelt.cn',
  'watertaxi.net',
  'west.metaverseaudio.com',
  'your.gay.cat',
  'ziggo.ws',
  'zynd.com',
  'spailbox',
  'spailbox',
  'realcambio.com',
  'watchnode.uni.cc',
  'gimme.wa.rez.se',
  'pyramidspel.com',
  'slopsbox.osocial.nu',
  'freenet6.de',
  'dodgit.com',
  'guerrillamail.org',
  'kaspop.com',
  'farifluset.mailexpire.com',
  'mailnull.com',
  'nospamfor.us',
  'nospam4.us',
  'spambox.us',
  'TempEMail.net',
  'tempinbox.com',
  'Mailinator.com',
  'astarmax.com',
  'sharklasers.com',
  'guerrillamail.info',
  'grr.la',
  'guerrillamail.biz',
  'guerrillamail.com',
  'guerrillamail.de',
  'guerrillamail.net',
  'guerrillamail.org',
  'guerrillamailblock.com',
  'pokemail.net',
  'spam4.me',
  'niwghx.com',
  'ebworkerzn.com',
  'wemel.site',
  'lihdaf.com',
  'bokikstore.com',
  'cmsonrails.com',
  'oceansofwaves.com',
  'krusada.org',
  'jomcs.com',
  'howtoanmobile.com',
  'nguyenusedcars.com',
  'nevadaibm.com',
  'apkalitestore.com',
  'bestbooksite.site',
  'gaswirth.me',
  'peapaws.com',
  '9phum.com',
  'system32.me',
  'dispostable.com',
  'zetmail.com',
  'mobiletrashmail.com',
  'twzhhq.online',
  '20minutemail.it',
  '33mail.com',
  'anonymousspeech.com',
  'vistomail.com',
  'anonymbox.com',
  'clipmail.eu',
  'deadaddress.com',
  'dispostable.com',
  'easytrashmail.com',
  'esbuah.nl',
  'company-mails.com',
  'emoisaocvho.com',
  'frysinger.org',
  'houndtech.com',
  'applaudthecause.org',
  'weildworks.org',
  'gitstage.org',
  'crushmyfood.org',
  'craft2kids.com',
  'na288.com',
  'saxophonexltd.com',
  'scadaerp.com',
  'dunsoi.com',
  'plotsandfoils.com',
  'emailisvalid.com',
  'emailsensei.com',
  'fadingmail.com',
  'armyspy.com',
  'superrito.com',
  'cuvox.de',
  'dayrep.com',
  'einrot.com',
  'fleckens.hu',
  'gustr.com',
  'jourrapide.com',
  'rhyta.com',
  'teleworm.us',
  'givmail.com',
  'gishpuppy.com',
  'prtnx.com',
  '20minutemail.com',
  '33mail.com',
  '34nm.com',
  'ahem.email',
  'ahem-email.com',
  'mail.ahem.email',
  'dispostable.com',
  'ledmask.com',
  'sangeks.com',
  'blogtron.com',
  'soccerfit.com',
  'gigilyn.com',
  'whatsonmyballot.com',
  'sumikang.com',
  'acmta.com',
  'jncylp.com',
  'bizimalem-support.de',
  'howitknow.com',
  'hotcoffeeclub.com',
  'connr.com',
  'subjectmap.net',
  'matapad.com',
  'getnada.com',
  'abyssmail.com',
  'boximail.com',
  'clrmail.com',
  'dropjar.com',
  'getairmail.com',
  'givmail.com',
  'inboxbear.com',
  'robot-mail.com',
  'tafmail.com',
  'vomoto.com',
  'zetmail.com',
  'harakirimail.com',
  'harakirimail.se',
  'yevme.com',
  'haribu.net',
  'incognitomail.org',
  'jetable.org',
  'lazyinbox.com',
  'lazyinbox.xyz',
  'mailforspam.com',
  'mfsa.ru',
  'email-jetable.fr',
  'mail-temporaire.fr',
  'mailtemporaire.fr',
  'mail-temporaire.com',
  'mailtemporaire.com',
  'easy-trash-mail.com',
  'easytrashmail.com',
  'email-jetable.biz.st',
  'padpub.co.cc',
  'jetable.co.cc',
  'email-jetable.co.tv',
  'mail-jetable.co.tv',
  'padpub.co.tv',
  'jetable.cz.cc',
  'email-jetable.cz.cc',
  'mail-jetable.cz.cc',
  'email-temporaire.cz.cc',
  'mail-temporaire.cz.cc',
  'mail7.io',
  'mailcatch.com',
  'nanozone.net',
  'mailexpire.com',
  'mailHz.me',
  'mailHazard.com',
  'mailHazard.us',
  'zebins.com',
  'zebins.eu',
  'amail4.me',
  'mailimate.com',
  '1stw.com',
  'tonggen.com',
  'meltmail.com',
  'mintemail.com',
  'firemailbox.club',
  'mohmal.com',
  'mytempemail.com',
  'mt2015.com',
  'thankyou2010.com',
  'noclickemail.com',
  'nospamme.rs',
  'spam.destroyer.email',
  'no-spammers.com',
  'randomail.net',
  'soodonims.com',
  'spambox.us',
  'spamfree24.org',
  'spamgourmet.com',
  'spamspot.com',
  'biyac.com',
  'uroid.com',
  'tempemail.net',
  'tempinbox.com',
  'DingBone.com',
  'FudgeRub.com',
  'LookUgly.com',
  'SmellFear.com',
  'aramidth.com',
  'temp-mail.org',
  'lackmail.ru',
  'lackmail.ru',
  'throwam.com',
  'throwawaymail.com',
  'trash-mail.com',
  'kurzpost.de',
  'objectmail.com',
  'proxymail.eu',
  'rcpt.at',
  'trash-mail.at',
  'trashmail.at',
  'trashmail.me',
  'wegwerfmail.de',
  'wegwerfmail.net',
  'wegwerfmail.org',
  'trashmail.ws',
  'trashmail.ws',
  'freeinbox.cyou',
  'yopmail.fr',
  'yopmail.net',
  'cool.fr.nf',
  'jetable.fr.nf',
  'nospam.ze.tc',
  'nomail.xl.cx',
  'mega.zik.dj',
  'speed.1s.fr',
  'courriel.fr.nf',
  'moncourrier.fr.nf',
  'monemail.fr.nf',
  'monmail.fr.nf',
  'mail.mezimages.net',
  'nice-4u.com',
  'mailsac.com',
  'meltmail.com',
  '0-mail.com',
  'get2mail.fr',
  'rppkn.com',
  'jetable.com',
  'spamgourmet.com',
  'haltospam.com',
  'iximail.com',
  'temporaryinbox.com',
  'mailincubator.com',
  'mailbidon.com',
  'cool.fr.nf',
  'courriel.fr.nf',
  'moncourrier.fr.nf',
  'monemail.fr.nf',
  'monmail.fr.nf',
  'kleemail.com',
  'xblogz.org',
  'link2mail.net',
  'spam.la',
  'spam.su',
  'mailinator.com',
  'mailinator2.com',
  'sogetthis.com',
  'mailin8r.com',
  'mailinator.net',
  'spamherelots.com',
  'thisisnotmyrealemail.com',
  'wh4f.org',
  'spamfree24.org',
  'spamfree24.com',
  'spamfree24.eu',
  'spamfree24.net',
  'spamfree24.info',
  'spamfree24.de',
  'trashymail.com',
  'mytrashmail.com',
  'mt2009.com',
  'pourri.fr',
  'dupemail.com',
  'email-jetable.com',
  'correo.blogos.net',
  'pookmail.com',
  '1-12.nl',
  '127-0-0-1.be',
  '3v1l0.com',
  'aliraheem.com',
  'aliscomputer.info',
  'bankofuganda.dontassrape.us',
  'black-arm.cn',
  'black-leg.cn',
  'black-tattoo.cn',
  'blacktattoo.cn',
  'bonaire.in',
  'casema.org',
  'churchofscientology.org.uk',
  'copcars.us',
  'definatelynotaspamtrap.com',
  'djlol.dk',
  'edwinserver.se',
  'fuzzy.weasel.net',
  'har2009.cn',
  'hermesonline.dk',
  'm.nonumberno.name',
  'jpshop.ru',
  'junk-yard.be',
  'junk-yard.eu',
  'laughingman.ath.cx',
  'linux.co.in',
  'lolinternets.com',
  'madcrazydesigns.com',
  'maleinhandsmint.czarkahan.net',
  'newkurdistan.com',
  'nigerianscam.dontassrape.us',
  'ninjas.dontassrape.us',
  'no-spam.cn',
  'omicron.token.ro',
  'pengodam.biz',
  'pirates.dontassrape.us',
  'pirazine.se',
  's.blackhat.lt',
  'sales.bot.nu',
  'sales.net-freaks.com',
  'sendmeshit.com',
  'slarvig.se',
  'slaskpost.cn',
  'slaskpost.se',
  'slop.jerkface.net',
  'slops.lazypeople.co.uk',
  'slops.quadrath.com',
  'slopsbox.com',
  'slopsbox.net',
  'slopsbox.org',
  'slopsbox.se',
  'slopsbox.slarvig.se',
  'slopsbox.spammesenseless.dk',
  'slopsbox.stivestoddere.dk',
]
