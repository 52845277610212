import { useRef, useEffect } from 'react'

// Custom useInterval Hooks
export default (callback: any, delay: number) => {
  const savedCallback = useRef<any>()
  useEffect(() => {
    savedCallback.current = callback
  })
  useEffect(() => {
    function tick() {
      if (savedCallback) {
        savedCallback.current()
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
