import { makeStyles, Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import React, { FC } from 'react'

const useStyles = makeStyles(() => ({
  typography: {
    fontSize: 36,
    fontWeight: 700,
  },
}))

interface SiteTitleProps {
  label: string
  handleClick?(): void
}

const SiteTitle: FC<SiteTitleProps> = props => {
  const { label, handleClick } = props
  const { typography } = useStyles()

  return (
    <Typography variant="h4" className={typography}>
      <Link
        to="/"
        style={{ textDecoration: 'none', color: 'black' }}
        onClick={handleClick}
      >
        {label}
      </Link>
    </Typography>
  )
}

export default SiteTitle
