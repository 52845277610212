import axios, { AxiosResponse } from 'axios'
import NodeRSA from 'node-rsa'
import CONFIG from '../../config'
import { API_SERVER_URL } from '../../constants/common'
import { ID_FOLDER_UPDATE_STATUS } from '../../constants/submitForm'
import { MessageErrorEnum } from '../../enum/message'
import { FolderStatusEnum } from '../../enum/submitForm'
import {
  CheckCodeIntoMailLinkArgs,
  GetInformationByLinkArgs,
  SaveDocumentInformationArgs,
  SubmitFormDocumentsArgs,
  SubmitFormSaveLinkArgs,
  SubmitFormToSend,
  UpdateCompanyIdentityArgs,
  UpdateCompanySiegeAddressArgs,
  UpdateCompanySiegeArgs,
  UpdatePersonAddressArgs,
  UpdatePersonIdentityArgs,
} from '../../interfaces/submitForm'
import { getFileObjectFromBlob } from '../../utils/file'

const SUBMIT_FORM_ROOT = 'submitForm'
const S3_ROOT = 'gateway/s3'
const FOLDER_SUBMIT_ROOT = 'services/dossierSoumission'
const GET_SUBMIT_FORM_INFO = 'getSubmittedForm/information'
const UPDATE_PERSON_ROOT = 'services/personne'
const UPDATE_COMPANY_ROOT = 'services/societe'

// const key = new NodeRSA()
// key.importKey(CONFIG.server.gateway.publicKey, 'pkcs8-public-pem')

// Save user form to database
export const sendSubmitForm = (
  data: SubmitFormToSend,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .post(`${API_SERVER_URL}/${SUBMIT_FORM_ROOT}/aggregate`, data)
      .then(res => {
        const saveError = `Erreur lors de la sauvegarde des données. Veuillez recommencer s'il vous plaît.`
        //   Error message from back or unkwnown error
        if (!res.data) {
          reject(new Error(saveError))
        }
        if (res.data[0].message || res.data[0].Fault) {
          reject(new Error(saveError))
        }
        if (!res.data[0] && !res.data[0]) {
          reject(new Error(saveError))
        }
        resolve(res.data[0])
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

// Save uploaded document information into database
const saveDocumentInformation = (
  data: SaveDocumentInformationArgs,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .post(`${API_SERVER_URL}/${FOLDER_SUBMIT_ROOT}/addDocument`, data)
      .then(res => {
        const saveError = `Quelques fichiers n'ont pas bien été sauvegardé.`
        if (res.data && res.data.Fault) {
          // reject(new Error(saveError))
          reject(new Error(saveError))
        }
        resolve({ success: 'ok' } as any)
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

// Send document to S3 and save link to view document into database
export const sendSubmitFormDocument = async (
  data: SubmitFormDocumentsArgs,
): Promise<AxiosResponse<any>> => {
  const { dossierName, storedFile, saveDocInfoApiArgs } = data
  try {
    // Get signed url using encrypted payload for security reason
    const payload = {
      fullXpath: `${dossierName}/${storedFile.documentType}/${storedFile.filename}`,
    }
    // const encryptedPayload = key.encrypt(JSON.stringify(payload), 'base64')

    const signedUrlData = await axios.get(
      `${API_SERVER_URL}/${S3_ROOT}/put-signed-url`,
      {
        params: {
          keyp: payload.fullXpath,
        },
      },
    )

    if (
      signedUrlData &&
      signedUrlData.data &&
      signedUrlData.data.putSignedUrl
    ) {
      const putSignedUrl = `${signedUrlData.data.putSignedUrl}`

      // Generate file using data from store
      const file = await getFileObjectFromBlob(storedFile)
      // console.log('!! file', file)

      // Revoke object URL (free path)
      URL.revokeObjectURL(storedFile.objectURL)

      const options = {
        headers: {
          'Content-Type': file.type,
        },
      }

      // Send file to S3
      return new Promise((resolve, reject) =>
        axios
          .put(putSignedUrl, file, options)
          .then(res => saveDocumentInformation(saveDocInfoApiArgs))
          .then(res => resolve(res))
          .catch(e => {
            reject(
              new Error(
                `Erreur lors de l'envoi du fichier "${storedFile.filename}"`,
              ),
            )
          }),
      )
    } else {
      throw new Error(MessageErrorEnum.SERVER_ERROR)
    }
  } catch (e) {
    throw new Error(MessageErrorEnum.SEND_FILE_ERROR)
  }
}

// Save read/edit folder links into database
export const submitFormSaveLink = (
  data: SubmitFormSaveLinkArgs,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .post(`${API_SERVER_URL}/${FOLDER_SUBMIT_ROOT}/addLinkDocument`, data)
      .then(res => {
        const saveError = `Quelques fichiers n'ont pas bien été sauvegardé.`
        if (res.data && res.data.Fault) {
          // reject(new Error(saveError))
          reject(new Error(saveError))
        }
        resolve({ success: 'ok' } as any)
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

// Check if code for link from user/agent's mail exist
export const checkCodeIntoMailLink = (
  data: CheckCodeIntoMailLinkArgs,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(`${API_SERVER_URL}/${FOLDER_SUBMIT_ROOT}/verifyLink`, {
        params: data,
      })
      .then(res => {
        const link =
          (res.data &&
            res.data.links &&
            res.data.links.link &&
            res.data.links.link.idDossier) ||
          null
        if (!link) {
          reject(
            new Error(
              `Ce lien n'existe pas. Veuillez bien vérifier s'il vous plaît`,
            ),
          )
        }
        resolve(data as any)
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

// Get submit form information according to checked link
export const getInformationByLink = (
  data: GetInformationByLinkArgs,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(`${API_SERVER_URL}/${GET_SUBMIT_FORM_INFO}/link`, {
        params: data,
      })
      .then(res => {
        if (res.data && res.data.length) {
          resolve(res.data as any)
        }

        reject(
          new Error(
            `Impossible de prendre les informations provenant du lien.`,
          ),
        )
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

// Update person identity
export const updatePersonIdentity = (
  data: UpdatePersonIdentityArgs,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .put(
        `${API_SERVER_URL}/${UPDATE_PERSON_ROOT}/updatePersonOnOnlineForm`,
        data,
      )
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

// Update person address
export const updatePersonAddress = (
  data: UpdatePersonAddressArgs,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .put(`${API_SERVER_URL}/${UPDATE_PERSON_ROOT}/updateAdress`, data)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

// Update company address
export const updateCompany = (
  data: UpdateCompanyIdentityArgs,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .put(`${API_SERVER_URL}/${UPDATE_COMPANY_ROOT}/updateSociety`, data)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

// Update company siege
export const updateCompanySiege = (
  data: UpdateCompanySiegeArgs,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .put(`${API_SERVER_URL}/${UPDATE_COMPANY_ROOT}/updateSiegeSocial`, data)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

// Update person address
export const updateCompanySiegeAddress = (
  data: UpdateCompanySiegeAddressArgs,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .put(`${API_SERVER_URL}/${UPDATE_COMPANY_ROOT}/updateAdress`, data)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

// Update folder status after update (deprecated)
// export const updateFolderStatus = (
//   idDossier: number,
// ): Promise<AxiosResponse<any>> =>
//   new Promise((resolve, reject) => {
//     // Set folder status "new" to false
//     const newPayload: UpdateStatusNewArgs = {
//       _putupdateStatutNouveau: {
//         idDossier,
//         value: false,
//       },
//     }
//     const updatePayload: UpdateStatusUpdateArgs = {
//       _putupdateStatutMAJ: {
//         idDossier,
//         value: true,
//       },
//     }
//     return axios
//       .put(
//         `${API_SERVER_URL}/${FOLDER_SUBMIT_ROOT}/updateStatutNouveau`,
//         newPayload,
//       )
//       .then(res => {
//         return axios.put(
//           `${API_SERVER_URL}/${FOLDER_SUBMIT_ROOT}/updateStatutMAJ`,
//           updatePayload,
//         )
//       })
//       .then(result => {
//         resolve(result)
//       })
//       .catch(e => {
//         reject(new Error(MessageErrorEnum.NETWORK_ERROR))
//       })
//   })

// Update folder status after update (new API)
export const updateFolderStatus = (
  idDossier: number,
  idStatutDossier = FolderStatusEnum.UPDATED,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .put(`${API_SERVER_URL}/${FOLDER_SUBMIT_ROOT}/updateStatutDossier`, {
        _putupdateStatut: {
          idDossier,
          idStatutDossier,
        },
      })
      .then(res => {
        resolve({ success: 'true' } as any)
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      }),
  )

// Get previously uploaded document
export const getPreviouslyUploadedDoc = (
  idDossier: string,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(`${API_SERVER_URL}/${FOLDER_SUBMIT_ROOT}/getListDocumentById`, {
        params: { idDossier },
      })
      .then(res => {
        if (res.data && res.data.documents) {
          resolve(res.data.documents.document || [])
        }

        reject(
          new Error(
            `Impossible de prendre la liste des dossier précédemment uploadé.`,
          ),
        )
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )
