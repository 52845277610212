import {
  Box,
  Drawer,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EDBMLogos from '../../../components/common/logos/EDBMLogos'
import EGMLogos from '../../../components/common/logos/EGMLogos'
import SimpleLink from '../../../components/customLinks/simpleLink'
import SiteTitle from '../../../components/siteTitle'
import CONFIG from '../../../config'
import { MenuLinkItem } from '../../../interfaces/common'
import { FormProps } from '../../../interfaces/form'
// import { switchLanguage } from '../../../redux/modules/appModule'
import { switchLanguage } from '../../../redux/modules/app'
import { RootState } from '../../../redux/store'

const useStyles = makeStyles((theme: Theme) => ({
  drawerContainer: {
    width: 320,
    padding: theme.spacing(1.5),
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image: {
    height: '30px',
    marginRight: theme.spacing(2),
  },

  linkButtonLogin: {
    padding: theme.spacing(1.5),
    color: '#384252',
    border: `solid 1px #D1D5DB`,
    borderRadius: 6,
    textDecoration: 'none',
    fontSize: 16,
    '&:hover': {
      backgroundColor: '#eee',
      cursor: 'pointer',
    },
    width: 122,
    textAlign: 'center',
  },
  linkButtonSignUp: {
    padding: theme.spacing(1.5),
    color: '#F9FAFB',
    backgroundColor: '#179D75',
    borderRadius: 6,
    textDecoration: 'none',
    fontSize: 16,
    width: 122,
    textAlign: 'center',
    marginTop: 16,
  },
}))

interface MobileHeaderProps {
  siteName: string
  menuItems: MenuLinkItem[]
  isDrawerOpen: boolean
  handleDrawerToggle(): void
  connexion: string
  signup: string
}

const MobileHeader: FC<MobileHeaderProps> = props => {
  const {
    siteName,
    menuItems,
    isDrawerOpen,
    handleDrawerToggle,
    connexion,
    signup,
  } = props

  const {
    drawerContainer,
    headerContainer,
    image,
    linkButtonLogin,
    linkButtonSignUp,
  } = useStyles()
  const dispatch = useDispatch()
  const { language } = useSelector((state: RootState) => state.app)

  const handleLanguageChange = (payload: FormProps) => {
    dispatch(switchLanguage(`${payload.value}`))
  }

  return (
    <Toolbar>
      <Drawer
        {...{
          anchor: 'left',
          open: isDrawerOpen,
          onClose: handleDrawerToggle,
        }}
      >
        <div className={drawerContainer}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <SiteTitle label={siteName} handleClick={handleDrawerToggle} />
              {/* <Box marginLeft={2}>
                <CustomSelect
                  name="language"
                  value={language}
                  items={LANGUAGES_ITEM}
                  handleChange={handleLanguageChange}
                  isSmall={true}
                />
              </Box> */}
            </Box>

            <IconButton
              onClick={handleDrawerToggle}
              size="small"
              style={{ padding: 10 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box margin={2} display="flex" flexDirection="column">
            {menuItems.map((item, index) => (
              <Box key={`head-menu-${index}`} marginY={1}>
                <SimpleLink
                  item={item}
                  isBold={true}
                  handleClick={handleDrawerToggle}
                />
              </Box>
            ))}
            <a
              href={`${CONFIG.clientSpaceUrl}/login`}
              target="blank"
              className={linkButtonLogin}
            >
              {connexion}
            </a>

            <a
              href={`${CONFIG.clientSpaceUrl}/sign-up`}
              target="blank"
              className={linkButtonSignUp}
            >
              {signup}
            </a>
          </Box>

          <Box width="100%" paddingY={2}>
            {/* <SearchBar /> */}
          </Box>

          <Box
            width="88%"
            display="flex"
            justifyContent="space-between"
            position="absolute"
            bottom={0}
            padding={3}
            alignItems="center"
          >
            <EDBMLogos />
            <EGMLogos />
          </Box>
        </div>
      </Drawer>

      <Box className={headerContainer}>
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <SiteTitle label="Orinasa" />
          </Box>
          <EDBMLogos />
        </Box>

        <Box display="flex">
          <IconButton onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Box>
    </Toolbar>
  )
}

export default MobileHeader
