export enum SubmitFormAddressEnum {
  IDENTITY = 'Identity',
  COMPANY = 'Company',
}

export enum LegalStatusEnum {
  GIE = '7',
  EI = '2',
  SARL = '1',
  SCS = '5',
  SNC = '4',
  SC = '6',
  SDET = '8',
  SARL_SARLU = '11',
  SA_SAU = '12',
  SARLU = '10',
  SA_AG = '13',
  SA_CA = '14',
  SA = '3',
  SAU = '9',
}

export enum FolderStatusEnum {
  NEW = 1,
  UPDATED = 2,
  TRAIT_OK = 3,
  TRAIT_KO = 4,
  RENDEZ_VOUS_OK = 5,
  DONE = 6,
  IN_PROGRESS = 7,
}
