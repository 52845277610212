import { Box, IconButton, makeStyles, Theme } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'
import React, { FC } from 'react'
import { FooterLinkData } from '../../../hooks/layout/useFooter'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconBox: {
    // marginRight: theme.spacing(1),
  },
  iconColor: {
    color: 'white',
  },
}))

const handleGoTo = (url: string) => () => {
  if (typeof window !== 'undefined') {
    window.open(url)
  }
}

const MediaIcons: FC<{ links: FooterLinkData[] }> = ({ links }) => {
  const { container, iconBox, iconColor } = useStyles()

  const icons: { [key: string]: any } = {
    facebook: <FacebookIcon className={iconColor} />,
    twitter: <TwitterIcon className={iconColor} />,
    linkedin: <LinkedInIcon className={iconColor} />,
  }

  return (
    <Box className={container}>
      {links.map(link => (
        <Box key={link.id} className={iconBox}>
          <IconButton onClick={handleGoTo(link.link_value)}>
            {icons[link.name_fr]}
          </IconButton>
        </Box>
      ))}
    </Box>
  )
}

export default MediaIcons
