import CONFIG from '../config'
import { LanguagesEnum } from '../enum/common'
import { SelectItem } from '../interfaces/common'

export const API_SERVER_URL = `${CONFIG.server.api.ip}`

export const YES_NO_ITEMS: SelectItem[] = [
  { label: '', value: '' },
  { label: 'Oui', value: '1' },
  { label: 'Non', value: '0' },
]

export const BAILLEUR_TYPE_ITEMS: SelectItem[] = [
  { label: '', value: '' },
  { label: 'Personne Morale', value: '1' },
  { label: 'Personne Physique', value: '0' },
]

export const MANDATAIRE_ITEMS: SelectItem[] = [
  { label: '', value: '' },
  { label: 'Mandataire', value: '1' },
  { label: 'Moi-même', value: '0' },
]

export const LANGUAGES_ITEM: SelectItem[] = [
  {
    label: 'FR',
    value: LanguagesEnum.FR,
  },
  {
    label: 'MG',
    value: LanguagesEnum.MG,
  },
  {
    label: 'EN',
    value: LanguagesEnum.EN,
  },
]

export const SWIPER_PARAMS = {
  slidesPerView: 3,
  spaceBetween: 60,
  breakpoints: {
    1200: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    640: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  },
}

export const SWIPER_PARAMS_FULL = {
  slidesPerView: 3,
  spaceBetween: 60,
  breakpoints: {
    1440: {
      slidesPerView: 5,
      spaceBetween: 16,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  },
}

export const MAX_FILE_SIZE_UPLOAD = 15 * 1024 * 1024 // 15 Mo
