import { FileStore } from '../interfaces/common'

export const getFileObjectFromBlob = async (file: FileStore): Promise<File> => {
  const resObject = await fetch(file.objectURL)
  const blob = await resObject.blob()
  return new File([blob], file.filename, { type: blob.type })
}

export const isFileCorrectType = (
  fileName: string,
  fileType: string,
): boolean => {
  let isCorrect = false

  const decompose = fileName.split('.')
  if (decompose.length > 1) {
    const currentFileType = decompose[decompose.length - 1]
    return fileType.toLowerCase() === currentFileType.toLowerCase()
  }

  return isCorrect
}

export const removeFileNameSpecialChar = (
  nameWithExtension: string,
): string => {
  const trunks = nameWithExtension.split('.')
  const filename = trunks[0]
  const fileExtension = trunks[trunks.length - 1]
  const withoutSpecialChars = filename.replace(/[^\w\s]/gi, '_')
  const withoutSpace = withoutSpecialChars.replace(/ /gi, '_')
  const transformed = fileExtension
    ? `${withoutSpace}.${fileExtension}`
    : withoutSpace
  return transformed
}
