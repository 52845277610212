import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import CONFIG from '../../config'
import { LINK_ERROR_CODE } from '../../constants/submitForm'
import { MessageTypeEnum } from '../../enum/message'
import { FolderStatusEnum, SubmitFormAddressEnum } from '../../enum/submitForm'
import { FormDataState } from '../../interfaces/formData'
import {
  SaveDocumentInformationArgs,
  SubmitFormLoadArgs,
  SubmitFormState,
  UpdateCompanyIdentityArgs,
  UpdateCompanySiegeAddressArgs,
  UpdateCompanySiegeArgs,
  UpdatePersonAddressArgs,
  UpdatePersonIdentityArgs,
} from '../../interfaces/submitForm'
import { formatDateHour, getArrayFromString } from '../../utils/common'
import {
  mailPayloadCreateCompany,
  mailPayloadNoDocFolder,
  mailPayloadUpdateFolder,
  mailToAgentPayloadUpdateFolder,
} from '../../utils/mail'
import {
  formatCompanyIdentityToSend,
  formatCompanySiegeToSend,
  formatIdentityToSend,
  formatReceivedCompanyIdentityData,
  formatReceivedCompanySiegeData,
  formatReceivedFolderyData,
  formatReceivedPersonData,
  formatSubmitFormToSend,
  getCompanyIdsForUpdate,
  getPersonIdsForUpdate,
} from '../../utils/submitForm'
import API from '../api'
import { loadQuartierSaga } from '../modules/address'
import { displayGeneralLoading } from '../modules/loading'
import { sendMailSaga } from '../modules/mail'
import { displayMessage } from '../modules/message'
import {
  addNotUploadedFiles,
  CheckLinkFromMailSaga,
  GetInformationByLinkSaga,
  HandleNoDocumentSaga,
  incNbTriedUploadedFiles,
  loadSubmitFormDataIntoStore,
  loadUploadedDocumentsIntoStore,
  LoadUploadedDocumentsIntoStoreSaga,
  SendMailSaveLinkSaga,
  SendSubmitFormAllDocumentSaga,
  sendSubmitFormDocumentSaga,
  SendSubmitFormDocumentSaga,
  setFolderInfo,
  setIsSavingSubmitForm,
  setLinkInfo,
  SubmitFormSaveLinkSaga,
  submitFormSaveLinkSaga,
} from '../modules/submitForm'
import store, { RootState } from '../store'

const getSubmitFormState = (state: RootState) => state.submitForm
const getFormDataState = (state: RootState) => state.formData

function* sendSubmitFormSE(): any {
  try {
    yield put(setIsSavingSubmitForm(true))
    const submitFormState: SubmitFormState = yield select(getSubmitFormState)
    const formDataState: FormDataState = yield select(getFormDataState)
    const currentDate = yield call(API.common.getWorldTime)
    const ipAddress = yield call(API.common.getCurrentIP)
    const formattedSubmitForm = formatSubmitFormToSend(
      submitFormState,
      formDataState.legalStatus,
      new Date(currentDate).getTime(),
      { ipAdress: `${ipAddress}` },
    )
    if (formattedSubmitForm) {
      const response = yield call(
        API.submitForm.sendSubmitForm,
        formattedSubmitForm,
      )
      // console.log('!! response', response)
      // const { numeroDossier, idDossier, idSociete } = response.idDossiers
      const dossierInfo = response.inboundPayloadDossier.idDossiers
      const { numeroDossier, idDossier } = dossierInfo
      const idSociete = response.idSociete
      if (!numeroDossier || !idDossier || typeof idSociete === 'undefined') {
        throw Error(
          'Données retournées après sauvegarde incomplètes. Veuillez nous informer de ce désagrément.',
        )
      } else {
        yield put(
          setFolderInfo({
            number: numeroDossier,
            id: +idDossier,
            idSociete,
            idStatutDossier: FolderStatusEnum.NEW,
          }),
        )
      }
    } else {
      throw Error(
        'Erreur de format de données. Veuillez bien vérifier les informations saisies',
      )
    }
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    // yield put(setIsSavingSubmitForm(false))

    // Tricks to wait that all confirmation mails are sent before sending no document mail (if no uploaded file)
    setTimeout(() => {
      store.dispatch(setIsSavingSubmitForm(false))
    }, 5000)
  }
}

export function* sendSubmitFormWatcher() {
  yield takeLatest('SUBMIT_FORM/SEND_DATA', sendSubmitFormSE)
}

// Send only one document form submit form
function* sendSubmitFormDocumentSE({
  payload,
}: ReturnType<SendSubmitFormDocumentSaga>) {
  try {
    yield call(API.submitForm.sendSubmitFormDocument, payload)
    yield put(
      displayMessage(
        `Fichier "${payload.storedFile.filename}" uploadé avec succès`,
        MessageTypeEnum.SUCCESS,
      ),
    )
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
    // Increment number of file which are tried to be uploaded
    yield put(addNotUploadedFiles(payload.storedFile.filename))
  } finally {
    yield put(incNbTriedUploadedFiles())
  }
}

export function* sendSubmitFormDocumentWatcher() {
  yield takeEvery('SUBMIT_FORM/SEND_DOCUMENT', sendSubmitFormDocumentSE)
}

// Send all documents from submit form
function* sendSubmitFormAllDocumentSE({
  payload,
}: ReturnType<SendSubmitFormAllDocumentSaga>) {
  try {
    const companyIdentity = store.getState().submitForm.companyIdentity

    const submitFormState: SubmitFormState = yield select(getSubmitFormState)
    const { id: idDossier, number: numeroDossier } = submitFormState.folderInfo
    // Upload each document
    const { dossierName, storedFiles, isUpdate } = payload
    storedFiles.forEach(storedFile => {
      const currentDocumentInfo: SaveDocumentInformationArgs = {
        _postaddDocument: {
          idDossier,
          idTypeDocument: +storedFile.documentType,
          nomDocument: storedFile.filename,
          path: `${numeroDossier}/${storedFile.documentType}/${storedFile.filename}`,
        },
      }
      store.dispatch(
        sendSubmitFormDocumentSaga({
          dossierName,
          storedFile,
          saveDocInfoApiArgs: currentDocumentInfo,
        }),
      )
      // store.dispatch(saveDocumentInfoSaga(currentDocumentInfo))
    })

    if (isUpdate) {
      // Update folder status to "MAJ"
      yield call(
        API.submitForm.updateFolderStatus,
        submitFormState.folderInfo.id,
      )
      // Send notification mail to user
      const notifMail = mailPayloadUpdateFolder({
        destinations: [submitFormState.identity.mail],
        folderNumber: numeroDossier,
        companyIdentity,
      })
      yield put(sendMailSaga(notifMail))

      // Send notification mail to agent
      const agentsMailAddresses = getArrayFromString(CONFIG.mail.agents)
      if (agentsMailAddresses.length) {
        const mailPayload = mailToAgentPayloadUpdateFolder({
          destinations: agentsMailAddresses,
          folderNumber: submitFormState.folderInfo.number,
          companyIdentity,
        })
        yield put(sendMailSaga(mailPayload))
      }
    }
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  }
}

export function* sendSubmitFormAllDocumentWatcher() {
  yield takeEvery('SUBMIT_FORM/SEND_ALL_DOCUMENTS', sendSubmitFormAllDocumentSE)
}

// Save links sent by mail into DB
function* submitFormSaveLinkSE({
  payload,
}: ReturnType<SubmitFormSaveLinkSaga>) {
  try {
    yield call(API.submitForm.submitFormSaveLink, payload)
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  }
}

export function* submitFormSaveLinkWatcher() {
  yield takeEvery('SUBMIT_FORM/SAVE_LINK', submitFormSaveLinkSE)
}

// Send mail to user and agent and save link into DB
function* sendMailAndSaveLinkSE({
  payload,
}: ReturnType<SendMailSaveLinkSaga>): any {
  try {
    const companyIdentity = store.getState().submitForm.companyIdentity

    const notFormattedDate = yield call(API.common.getWorldTime)
    const date = formatDateHour(new Date(notFormattedDate))

    const { userInfo, agentInfo, linkPayload, folderNumber } = payload
    const userMail = mailPayloadCreateCompany({
      destinations: userInfo.addresses,
      folderNumber,
      code: userInfo.code,
      date,
      isForUser: userInfo.isUser,
      companyIdentity,
    })
    const agentMail = mailPayloadCreateCompany({
      destinations: agentInfo.addresses,
      folderNumber,
      code: agentInfo.code,
      date,
      isForUser: agentInfo.isUser,
      companyIdentity,
    })

    // Send mail to user
    yield put(sendMailSaga(userMail))
    // Send mail to agent
    yield put(sendMailSaga(agentMail))
    // Save links into DB
    yield put(submitFormSaveLinkSaga(linkPayload))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  }
}

export function* sendMailAndSaveLinkWatcher() {
  yield takeEvery('SUBMIT_FORM/SEND_MAIL_SAVE_LINK', sendMailAndSaveLinkSE)
}

// Check link from mail while user/agent click into it
function* checkLinkFromMailSE({
  payload,
}: ReturnType<CheckLinkFromMailSaga>): any {
  try {
    yield put(displayGeneralLoading(true))
    const idDossier = yield call(API.submitForm.checkCodeIntoMailLink, payload)
    // If a folder ID is returned, that means that the type and the link code ("pod") are true
    if (idDossier) {
      yield put(setLinkInfo(payload))
    }
  } catch (e) {
    // yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
    yield put(setLinkInfo({ type: LINK_ERROR_CODE, pod: LINK_ERROR_CODE })) // Save false data if error (to display permanent error)
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* checkLinkFromMailWatcher() {
  yield takeEvery('SUBMIT_FORM/CHECK_LINK_FORM_MAIL', checkLinkFromMailSE)
}

// Get previously saved information using link from mail (after this mail was checked)
function* getInformationByLinkSE({
  payload,
}: ReturnType<GetInformationByLinkSaga>): any {
  try {
    yield put(displayGeneralLoading(true))
    const informations = yield call(
      API.submitForm.getInformationByLink,
      payload,
    )
    // console.log('!!', informations)
    if (informations.length >= 3) {
      const formattedCompanyIdentity = formatReceivedCompanyIdentityData(
        informations[0].SocietyOutPayload,
      )
      const formattedCompanySiege = formatReceivedCompanySiegeData(
        informations[0].SocietyOutPayload,
      )
      const formattedIdentity = formatReceivedPersonData(
        informations[1].PersonOutPayload,
      )
      const companyIdsForUpdate = getCompanyIdsForUpdate(
        informations[0].SocietyOutPayload,
      )
      const personIdsForUpdate = getPersonIdsForUpdate(
        informations[1].PersonOutPayload,
      )

      const folderInfo = formatReceivedFolderyData(
        informations[2].DossierOutPayload,
        informations[0].SocietyOutPayload,
      )

      // const uploadedDocList = formatUploadedDocumentsData(
      //   informations[2].DossierOutPayload,
      // )

      if (
        formattedCompanyIdentity &&
        formattedCompanySiege &&
        formattedIdentity &&
        companyIdsForUpdate &&
        personIdsForUpdate &&
        folderInfo
      ) {
        // Note: status form is acronym from back, need to be converted to id
        const companyIdentity = {
          ...formattedCompanyIdentity,
          statusForm:
            payload.statusMap.get(formattedCompanyIdentity.statusForm) || '',
        }
        const submitFormLoadData: SubmitFormLoadArgs = {
          identity: formattedIdentity,
          // companyIdentity: formattedCompanyIdentity,
          companyIdentity,
          companySiege: formattedCompanySiege,
          idsForUpdate: {
            personne: personIdsForUpdate,
            societe: companyIdsForUpdate,
          },
        }
        // console.log('!!', submitFormLoadData)

        // Get list of region, district, ..., according to identity address from back
        yield put(
          // loadAddressSaga({
          loadQuartierSaga({
            addressToChange: SubmitFormAddressEnum.IDENTITY,
            data: formattedIdentity.address,
          }),
        )

        // Get list of region, district, ..., according to siege social address from back
        yield put(
          // loadAddressSaga({
          loadQuartierSaga({
            addressToChange: SubmitFormAddressEnum.COMPANY,
            data: formattedCompanySiege.address,
          }),
        )

        // Set all needed submit form state
        yield put(loadSubmitFormDataIntoStore(submitFormLoadData))

        // Set folder info into store
        yield put(setFolderInfo(folderInfo))

        // Set previous uploaded documents list into store (deprecated: list loaded on component mount)
        // yield put(loadUploadedDocumentsIntoStore(uploadedDocList))
      } else {
        throw Error(`Certaines informations n'ont pas pu être formattées.`)
      }
    } else {
      throw Error(`Certaines informations n'ont pas pu être récupérées.`)
    }
    // yield put(setLinkInfo(payload))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* getInformationByLinkWatcher() {
  yield takeEvery('SUBMIT_FORM/GET_INFO_BY_LINK', getInformationByLinkSE)
}

function* loadUploadedDocumentsIntoStoreSE({
  payload,
}: ReturnType<LoadUploadedDocumentsIntoStoreSaga>): any {
  try {
    const folderId = payload
    yield put(displayGeneralLoading(true))
    const uploadedDoc = yield call(
      API.submitForm.getPreviouslyUploadedDoc,
      `${folderId}`,
    )
    // Set previous uploaded documents list into store
    yield put(loadUploadedDocumentsIntoStore(uploadedDoc))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* loadUploadedDocumentsIntoStoreWatcher() {
  yield takeLatest(
    'SUBMIT_FORM/LOAD_UPLOADED_FILES_INTO_STORE_SAGA',
    loadUploadedDocumentsIntoStoreSE,
  )
}

function* updatePersonSE() {
  try {
    yield put(setIsSavingSubmitForm(true))
    const companyIdentity = store.getState().submitForm.companyIdentity

    const submitFormState: SubmitFormState = yield select(getSubmitFormState)
    const formattedPersonPayload = formatIdentityToSend(
      submitFormState.identity,
    )
    if (formattedPersonPayload) {
      const {
        personne: { idPersonne, idAdresse },
      } = submitFormState.idsForUpdate
      const {
        personne: {
          nom,
          prenom,
          tel,
          tel2,
          e_mail,
          idRole,
          societe_mandataire,
        },
      } = formattedPersonPayload
      const updateIdentity: UpdatePersonIdentityArgs = {
        _postaddPersonOnOnlineForm: {
          e_mail,
          nom,
          prenom,
          tel,
          id_role: idRole,
          societe_mandataire,
          idAdresse,
          idPersonne,
          tel2,
        },
      }
      const updateAddress: UpdatePersonAddressArgs = {
        _putupdateAdress: {
          ...formattedPersonPayload.adresse,
          idAdresse,
        },
      }
      yield call(API.submitForm.updatePersonIdentity, updateIdentity)
      yield call(API.submitForm.updatePersonAddress, updateAddress)

      // Update folder status to "MAJ"
      yield call(
        API.submitForm.updateFolderStatus,
        submitFormState.folderInfo.id,
      )

      // Send notification mail to user
      const notifMail = mailPayloadUpdateFolder({
        destinations: [submitFormState.identity.mail],
        folderNumber: submitFormState.folderInfo.number,
        companyIdentity,
      })
      yield put(sendMailSaga(notifMail))

      // Send notification mail to agent
      const agentsMailAddresses = getArrayFromString(CONFIG.mail.agents)
      if (agentsMailAddresses.length) {
        const mailPayload = mailToAgentPayloadUpdateFolder({
          destinations: agentsMailAddresses,
          folderNumber: submitFormState.folderInfo.number,
          companyIdentity,
        })
        yield put(sendMailSaga(mailPayload))
      }

      yield put(
        displayMessage(
          'Données mises à jour avec succès',
          MessageTypeEnum.SUCCESS,
        ),
      )
    } else {
      throw Error(
        'Erreur de format de données. Veuillez bien vérifier les informations saisies',
      )
    }
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(setIsSavingSubmitForm(false))
  }
}

export function* updatePersonWatcher() {
  yield takeLatest('SUBMIT_FORM/UPDATE_PERSON', updatePersonSE)
}

function* updateCompanySE() {
  try {
    yield put(setIsSavingSubmitForm(true))
    const companyIdentity = store.getState().submitForm.companyIdentity

    const submitFormState: SubmitFormState = yield select(getSubmitFormState)
    const formDataState: FormDataState = yield select(getFormDataState)

    const formattedCompanyPayload = formatCompanyIdentityToSend(
      submitFormState.companyIdentity,
      formDataState.legalStatus,
      Date.now(),
    )
    if (formattedCompanyPayload) {
      const {
        personne: { idPersonne },
        societe: { idSociete, idSiegeSocial },
      } = submitFormState.idsForUpdate
      const {
        numeroDossier, // Remove numeroDossier from payload for update
        ...updatedPayload
      } = formattedCompanyPayload
      // const updatedPayload = formattedCompanyPayload
      const updateIdentity: UpdateCompanyIdentityArgs = {
        _putupdateSociety: {
          ...updatedPayload,
          // nombreAssociePersMorale: +updatedPayload.nombreAssociePersMorale,
          idPersonne,
          idSociete,
          idSiegeSocial,
        },
      }
      yield call(API.submitForm.updateCompany, updateIdentity)

      // Update folder status to "MAJ"
      yield call(
        API.submitForm.updateFolderStatus,
        submitFormState.folderInfo.id,
      )

      // Send notification mail to user
      const notifMail = mailPayloadUpdateFolder({
        destinations: [submitFormState.identity.mail],
        folderNumber: submitFormState.folderInfo.number,
        companyIdentity,
      })
      yield put(sendMailSaga(notifMail))

      // Send notification mail to agent
      const agentsMailAddresses = getArrayFromString(CONFIG.mail.agents)
      if (agentsMailAddresses.length) {
        const mailPayload = mailToAgentPayloadUpdateFolder({
          destinations: agentsMailAddresses,
          folderNumber: submitFormState.folderInfo.number,
          companyIdentity,
        })
        yield put(sendMailSaga(mailPayload))
      }

      yield put(
        displayMessage(
          'Données  mises à jour avec succès',
          MessageTypeEnum.SUCCESS,
        ),
      )
    } else {
      throw Error(
        'Erreur de format de données. Veuillez bien vérifier les informations saisies',
      )
    }
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(setIsSavingSubmitForm(false))
  }
}

export function* updateCompanyWatcher() {
  yield takeLatest('SUBMIT_FORM/UPDATE_COMPANY', updateCompanySE)
}

function* updateCompanySiegeSE() {
  try {
    yield put(setIsSavingSubmitForm(true))
    const companyIdentity = store.getState().submitForm.companyIdentity

    const submitFormState: SubmitFormState = yield select(getSubmitFormState)
    const formattedSiegePayload = formatCompanySiegeToSend(
      submitFormState.companySiege,
    )
    if (formattedSiegePayload) {
      const {
        societe: { idSiegeSocial, idAdresse },
      } = submitFormState.idsForUpdate
      const updateSiege: UpdateCompanySiegeArgs = {
        _putupdateSiegeSocial: {
          ...formattedSiegePayload.siege,
          idSiege: idSiegeSocial,
          adresseSiegeSocial: idAdresse,
        },
      }
      const updateAddress: UpdateCompanySiegeAddressArgs = {
        _putupdateAdressSociety: {
          ...formattedSiegePayload.adresse,
          idAdresse,
        },
      }
      yield call(API.submitForm.updateCompanySiege, updateSiege)
      yield call(API.submitForm.updateCompanySiegeAddress, updateAddress)

      // Update folder status to "MAJ"
      yield call(
        API.submitForm.updateFolderStatus,
        submitFormState.folderInfo.id,
      )

      // Send notification mail to user
      const notifMail = mailPayloadUpdateFolder({
        destinations: [submitFormState.identity.mail],
        folderNumber: submitFormState.folderInfo.number,
        companyIdentity,
      })
      yield put(sendMailSaga(notifMail))

      // Send notification mail to agent
      const agentsMailAddresses = getArrayFromString(CONFIG.mail.agents)
      if (agentsMailAddresses.length) {
        const mailPayload = mailToAgentPayloadUpdateFolder({
          destinations: agentsMailAddresses,
          folderNumber: submitFormState.folderInfo.number,
          companyIdentity,
        })
        yield put(sendMailSaga(mailPayload))
      }

      yield put(
        displayMessage(
          'Données  mises à jour avec succès',
          MessageTypeEnum.SUCCESS,
        ),
      )
    } else {
      throw Error(
        'Erreur de format de données. Veuillez bien vérifier les informations saisies',
      )
    }
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(setIsSavingSubmitForm(false))
  }
}

export function* updateCompanySiegeWatcher() {
  yield takeLatest('SUBMIT_FORM/UPDATE_COMPANY_SIEGE', updateCompanySiegeSE)
}

function* handleNoDocumentSE({ payload }: ReturnType<HandleNoDocumentSaga>) {
  try {
    // yield put(displayGeneralLoading(true))
    yield put(setIsSavingSubmitForm(true))
    const companyIdentity = store.getState().submitForm.companyIdentity

    const { idDossier, userMail, folderNumber } = payload

    // Update folder status to "TRAIT_KO"
    yield call(
      API.submitForm.updateFolderStatus,
      idDossier,
      FolderStatusEnum.TRAIT_KO,
    )

    // Send notification mail to user
    const notifMail = mailPayloadNoDocFolder({
      destinations: [userMail],
      folderNumber,
      companyIdentity,
    })
    yield put(sendMailSaga(notifMail))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(setIsSavingSubmitForm(false))
    // yield put(setIsSavingSubmitForm(false))
  }
}

export function* handleNoDocumentWatcher() {
  yield takeLatest('SUBMIT_FORM/HANDLE_NO_DOCUMENT_SAGA', handleNoDocumentSE)
}
