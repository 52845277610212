import axios, { AxiosResponse } from 'axios'
import { API_SERVER_URL } from '../../constants/common'
import { MessageErrorEnum } from '../../enum/message'
import { DocumentsListArgs } from '../../interfaces/formData'

const PERSON_ROOT = 'services/personne'
const SOCIETE_ROOT = 'services/societe'
const ADDRESS_ROOT = 'services/localisation'

export const getPersonRoles = (): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(`${API_SERVER_URL}/${PERSON_ROOT}/listOfPersonRoleSociety`)
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

export const getTypeBailleur = (): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(`${API_SERVER_URL}/${SOCIETE_ROOT}/getTypeBailleur`)
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

export const getTypeContrat = (): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(`${API_SERVER_URL}/${SOCIETE_ROOT}/getTypeContrat`)
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

export const getSumitFormDocList = (
  data: DocumentsListArgs,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .post(`${API_SERVER_URL}/clone/aggregate`, data) // Use "post" instead of "get" because payload not received into ballerina
      .then(res => {
        //   Error message from back or unkwnown error
        if (res.data[0].message || res.data[0].Fault) {
          reject(new Error(MessageErrorEnum.SERVER_ERROR))
        }
        resolve(res.data)
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

export const getAllAddresses = (): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(
        `${API_SERVER_URL}/${ADDRESS_ROOT}/getCommuneFokontanyProvince/{1,2,3,4,5,6}`,
      )
      .then(res => {
        if (
          res.data &&
          res.data.arrondissements &&
          res.data.arrondissements.arrondissement
        ) {
          resolve(res.data.arrondissements.arrondissement)
        }
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

export const getAllArrondissement = (): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(
        `${API_SERVER_URL}/${ADDRESS_ROOT}/getArrondissementCommuneDistrict/{1,2,3,4,5,6}`,
      )
      .then(res => {
        if (res.data && res.data.arrs && res.data.arrs.arr) {
          resolve(res.data.arrs.arr)
        }
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )
