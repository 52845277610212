import { Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import useFooter from '../../hooks/layout/useFooter'
import { RootState } from '../../redux/store'
import { getInternationalizedValue } from '../../utils/common'
import Copyright from './copyright'
import MediaIcons from './mediaIcons'
import { Link } from 'gatsby'

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    marginTop: 'auto',
    color: '#FFFFFF',
    padding: '0 80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 310,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  link: {
    color: '#FFF',
    textDecoration: 'none',
  },
  typography: {
    fontSize: 36,
    fontWeight: 700,
    marginBottom: 10,
  },
  containerOrinasa: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerLink: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginBottom: 10,
    },
  },
}))

const Footer: FC = () => {
  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const data = useFooter()

  const right = getInternationalizedValue(data.footer, 'right', language)

  return (
    <footer
      className={classes.footer}
      style={{
        background: `url(${data.footer.bg_image.publicURL}) repeat top 170% center /  100%`,
      }}
    >
      <div style={{ height: 52 }} />
      <div className={classes.container}>
        <div className={classes.containerLink}>
          {data.footer.common_links.map(link => (
            <Link key={link.id} className={classes.link} to={link.link_value}>
              {getInternationalizedValue(link, 'name', language)}
            </Link>
          ))}
        </div>
        <div className={classes.containerOrinasa}>
          <Typography variant="h4" className={classes.typography}>
            {data.footer_media.site_title}
          </Typography>
          <Link className={classes.link} to={'/'}>
            {data.footer_media.domaine_name}
          </Link>
          <MediaIcons links={data.footer_media.links} />
        </div>
      </div>
      <Copyright right={right} />
    </footer>
  )
}

export default Footer
