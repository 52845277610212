import { SelectItem } from '../interfaces/common'
import { AddressState, AddressToSend } from '../interfaces/submitForm'
import { checkStringLength } from './common'

export const convertAddressDataToItem = (data: any): SelectItem[] => {
  const results = data.map((d: any) => ({ label: d.name, value: d.id }))
  results.unshift({ label: '', value: '' })
  return results
}

export const isAddressFieldsValid = (fields: AddressState): boolean => {
  const {
    province,
    region,
    commune,
    district,
    arrondissement,
    quartier,
    localAddress,
  } = fields
  return (
    !!province &&
    !!region &&
    !!commune &&
    !!district &&
    !!arrondissement &&
    !!quartier &&
    checkStringLength(localAddress)
  )
}

export const formatAddressToSend = (
  address: AddressState,
): AddressToSend | undefined => {
  const {
    province,
    region,
    district,
    commune,
    arrondissement,
    quartier,
    localAddress,
  } = address

  // Avoid sending 0 if it is an empty string ''
  if (
    !province ||
    !region ||
    !district ||
    !commune ||
    !arrondissement ||
    !quartier
  ) {
    return undefined
  }

  return {
    idProvince: +province,
    idRegion: +region,
    idDistrict: +district,
    idCommune: +commune,
    idArrondissement: +arrondissement,
    idFokontany: +quartier,
    adresse: localAddress,
  }
}
