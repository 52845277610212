import axios, { AxiosResponse } from 'axios'
import { API_SERVER_URL } from '../../constants/common'
import { MessageErrorEnum } from '../../enum/message'

export const getStatisticOverviewApi = (): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(`${API_SERVER_URL}/api/statistics/overviews`)
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.GET_STAT_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

export const getStatisticIntervalApi = (): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(`${API_SERVER_URL}/api/statistics/treatments/intervals`)
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.GET_STAT_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

export const getStatisticGraphApi = (): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(`${API_SERVER_URL}/api/statistics/graphs`)
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.GET_STAT_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )
