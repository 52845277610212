import { createSelector } from 'reselect'
import { LegalStatusData } from '../../hooks/useAllLegalStatus'
import { OptionItem, SelectItem } from '../../interfaces/common'
import {
  AllAddresses,
  AllArrondissementData,
  FormDataState,
  LegalStatusFormData,
  SubmitFormDocItem,
} from '../../interfaces/formData'
import { getNumberId } from '../../utils/common'
import {
  formatAllAddresses,
  formatAllArrondissement,
} from '../../utils/formData'
import { typedAction } from '../utils'

const initialState: FormDataState = {
  personRoles: [],
  typeBailleur: [],
  typeContract: [],
  submitFormDocList: [],
  legalStatus: [],
  allAddresses: [],
  allFokontany: [],
  allArrondissement: [],
  arrondissementItems: [],
}

// Use reselect to get number of documents to upload
const documentsListSelector = (documentsList: SubmitFormDocItem[]) =>
  documentsList

export const getDocNumberToUpload = createSelector(
  [documentsListSelector],
  documentsList =>
    documentsList.reduce(
      (accumulator, currentDoc) => accumulator + currentDoc.nbUploader,
      0,
    ),
)

// Use reselect to get arrondissement for Tana only
const arrondissementSelector = (arrondissementList: AllArrondissementData[]) =>
  arrondissementList

export const getTanaArrondissementItems = createSelector(
  [arrondissementSelector],
  arrondissementList =>
    arrondissementList
      .filter(d => d.idProvince === '1')
      .map(a => ({
        label: `${a.nameArrondissement} / ${a.nameDistrict}`,
        value: a.idArrondissement,
      })),
)

// Actions creator
export const getPersonRoleSaga = () =>
  typedAction('FORM_DATA/GET_PERSON_ROLES_SAGA')
export type GetPersonRoleSaga = typeof getPersonRoleSaga

export const getTypeBailleurSaga = () =>
  typedAction('FORM_DATA/GET_TYPE_BAILLEUR_SAGA')
export type GetTypeBailleurSaga = typeof getTypeBailleurSaga

export const getTypeContractSaga = () =>
  typedAction('FORM_DATA/GET_TYPE_CONTRACT_SAGA')
export type GetTypeContractSaga = typeof getTypeContractSaga

export const getSubmitFormDocListSaga = () =>
  typedAction('FORM_DATA/GET_SUBMIT_FORM_DOC_LIST_SAGA')
export type GetSubmitFormDocListSaga = typeof getSubmitFormDocListSaga

export const setPersonRoles = (payload: OptionItem[]) =>
  typedAction('FORM_DATA/SET_PERSON_ROLES', payload)
export type SetPersonRoles = typeof setPersonRoles

export const setTypeBailleur = (payload: SelectItem[]) =>
  typedAction('FORM_DATA/SET_TYPE_BAILLEUR', payload)
export type SetTypeBailleur = typeof setTypeBailleur

export const setTypeContract = (payload: SelectItem[]) =>
  typedAction('FORM_DATA/SET_TYPE_CONTRACT', payload)
export type SetTypeContract = typeof setTypeContract

export const setSubmitFormDocList = (payload: SubmitFormDocItem[]) =>
  typedAction('FORM_DATA/SET_SUBMIT_FORM_DOC_LIST', payload)
export type SetSubmitFormDocList = typeof setSubmitFormDocList

export const setLegalStatusFormData = (payload: LegalStatusData[]) =>
  typedAction('FORM_DATA/SET_LEGAL_STATUS_FORM_DATA', payload)
export type SetLegalStatusFormData = typeof setLegalStatusFormData

export const getAllAddressesSaga = () =>
  typedAction('FORM_DATA/GET_ALL_ADDRESSES_SAGA')
export type GetAllAddressesSaga = typeof getAllAddressesSaga

export const setAllAddressesAndFokontany = (payload: AllAddresses[]) =>
  typedAction('FORM_DATA/SET_ALL_ADDRESSES_AND_FOKONTANY', payload)
export type SetAllAddressesAndFokontany = typeof setAllAddressesAndFokontany

export const getAllArrondissementSaga = () =>
  typedAction('FORM_DATA/GET_ALL_ARRONDISSEMENT_SAGA')
export type GetAllArrondissementSaga = typeof getAllArrondissementSaga

export const setAllArrondissement = (payload: AllArrondissementData[]) =>
  typedAction('FORM_DATA/SET_ALL_ARRONDISSEMENT', payload)
export type SetAllArrondissement = typeof setAllArrondissement

// Actions type
type FormDataAction = ReturnType<
  | SetPersonRoles
  | SetTypeBailleur
  | SetTypeContract
  | SetSubmitFormDocList
  | SetLegalStatusFormData
  | SetAllAddressesAndFokontany
  | SetAllArrondissement
>

// Reducer
export const FormDataReducer = (
  state = initialState,
  action: FormDataAction,
): FormDataState => {
  switch (action.type) {
    case 'FORM_DATA/SET_PERSON_ROLES':
      return { ...state, personRoles: [...action.payload] }

    case 'FORM_DATA/SET_TYPE_BAILLEUR':
      return { ...state, typeBailleur: [...action.payload] }

    case 'FORM_DATA/SET_TYPE_CONTRACT':
      return { ...state, typeContract: [...action.payload] }

    case 'FORM_DATA/SET_SUBMIT_FORM_DOC_LIST':
      return { ...state, submitFormDocList: [...action.payload] }

    case 'FORM_DATA/SET_LEGAL_STATUS_FORM_DATA':
      const legalStatus: LegalStatusFormData[] = action.payload.map(l => {
        const id = getNumberId(l.id)
        return { id, status: l.STATUS }
      })
      return { ...state, legalStatus: [...legalStatus] }

    case 'FORM_DATA/SET_ALL_ADDRESSES_AND_FOKONTANY':
      const { allAddresses, allFokontany } = formatAllAddresses(action.payload)

      return {
        ...state,
        allAddresses: [...allAddresses],
        allFokontany: [...allFokontany],
      }

    case 'FORM_DATA/SET_ALL_ARRONDISSEMENT':
      const {
        allArrondissement,
        arrondissementItems,
      } = formatAllArrondissement(action.payload)

      return {
        ...state,
        allArrondissement: [...allArrondissement],
        arrondissementItems: [...arrondissementItems],
      }

    default:
      return state
  }
}
