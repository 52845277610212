import { LanguagesEnum } from '../../enum/common'
import { AppState } from '../../interfaces/app'
import { typedAction } from '../utils'

const initialState: AppState = {
  theme: 'light',
  language: LanguagesEnum.FR,
}

// Actions creator
export const toggleTheme = () => typedAction('APP/TOGGLE_THEME')
export type ToggleTheme = typeof toggleTheme

export const switchLanguage = (language: string) =>
  typedAction('APP/SWITCH_LANGUAGE', language)
export type switchLanguage = typeof switchLanguage

// Actions type
type AppAction = ReturnType<ToggleTheme | switchLanguage>

// Reducer
export const AppReducer = (
  state = initialState,
  action: AppAction,
): AppState => {
  switch (action.type) {
    case 'APP/TOGGLE_THEME':
      return { ...state, theme: state.theme === 'light' ? 'dark' : 'light' }
    case 'APP/SWITCH_LANGUAGE':
      return { ...state, language: action.payload }
    default:
      return state
  }
}
