import { connect } from 'react-redux'
import { closeMessage } from '../../../redux/modules/message'
import { RootState } from '../../../redux/store'
import GlobalSnackbar from './globalSnackbar'

const mapStateToProps = (state: RootState) => ({
  ...state.message,
})

const mapDispatchToProps = { closeMessage }

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSnackbar)
