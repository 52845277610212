import { MailSendPayload } from '../../interfaces/mail'
import { typedAction } from '../utils'

interface MailState {
  isLoading: boolean
}

const initialState: MailState = {
  isLoading: false,
}

// Actions creator
export const sendMailSaga = (payload: MailSendPayload) =>
  typedAction('MAIL/SEND_SAGA', payload)
export type SendMailSaga = typeof sendMailSaga

export const sendMailLoading = (isLoading: boolean) =>
  typedAction('MAIL/SEND_LOADING', isLoading)
export type SendMailLoading = typeof sendMailLoading

// Actions type
type MailAction = ReturnType<SendMailLoading>

// Reducer
export const MailReducer = (
  state = initialState,
  action: MailAction,
): MailState => {
  switch (action.type) {
    case 'MAIL/SEND_LOADING':
      return { ...state, isLoading: action.payload }
    default:
      return state
  }
}
