import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    display: 'flex',
    color: 'white',
  },
  centerContent: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
}))

interface CopyrightProps {
  right: string
}

const Copyright: FC<CopyrightProps> = props => {
  const { right } = props

  const { container, centerContent } = useStyles()

  return (
    <Box className={container} paddingY={2}>
      <Box className={centerContent}>
        <Typography variant="body2">
          Copyright © {new Date().getFullYear()} EBDM Economic &nbsp;
        </Typography>
      </Box>
      <Box className={centerContent}>
        <Typography variant="body2">
          Development Board of Madagascar -&nbsp;
        </Typography>
      </Box>
      <Box className={centerContent}>
        <Typography variant="body2">{right}</Typography>
      </Box>
    </Box>
  )
}

export default Copyright
