export enum MessageErrorEnum {
  SERVER_ERROR = 'Un erreur serveur est survenu. Veuillez réessayer ultérieurement.',
  NETWORK_ERROR = 'Un erreur réseau est survenu. Veuillez vérifier votre connexion internet.',
  SEND_FILE_ERROR = `Un erreur est survenu lors de l'envoi du fichier`,
  MAIL_NOT_SENT_ERROR = `Un erreur est survenu lors de l'envoi du mail`,
  GET_STAT_ERROR = `Un erreur est survenu lors de la récupération des statistiques`,
}

export enum MessageTypeEnum {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
