import { typedAction } from '../utils'
import { LoadingState } from '../../interfaces/loading'

const initialState: LoadingState = {
  isDisplayed: false,
}

// Actions creator
export const displayGeneralLoading = (isDisplayed: boolean) =>
  typedAction('LOADING/DISPLAY', isDisplayed)
export type DisplayGeneralLoading = typeof displayGeneralLoading

// Actions type
type LoadingAction = ReturnType<DisplayGeneralLoading>

// Reducer
export const LoadingReducer = (
  state = initialState,
  action: LoadingAction,
): LoadingState => {
  switch (action.type) {
    case 'LOADING/DISPLAY':
      return { ...state, isDisplayed: action.payload }
    default:
      return state
  }
}
