import axios, { AxiosResponse } from 'axios'
import { API_SERVER_URL } from '../../constants/common'
import { MessageErrorEnum } from '../../enum/message'

const ADDRESS_ROOT = 'services/localisation'

export const getProvince = (): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(`${API_SERVER_URL}/${ADDRESS_ROOT}/province`)
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

export const getRegionByProvince = (
  provinceID: string,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(
        `${API_SERVER_URL}/${ADDRESS_ROOT}/regionByProvince?province_id=${provinceID}`,
      )
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

export const getDistrictByRegion = (
  regionID: string,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(
        `${API_SERVER_URL}/${ADDRESS_ROOT}/districtByRegion?region_id=${regionID}`,
      )
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

export const getCommuneByDistrict = (
  districtID: string,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(
        `${API_SERVER_URL}/${ADDRESS_ROOT}/commonByDistrict?district_id=${districtID}`,
      )
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

export const getArrondissementByCommune = (
  communeID: string,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(
        `${API_SERVER_URL}/${ADDRESS_ROOT}/boroughByCommon?common_id=${communeID}`,
      )
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )

export const getQuartierByArrondissement = (
  arrondissementID: string,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .get(
        `${API_SERVER_URL}/${ADDRESS_ROOT}/fokontanyByBorough?borough_id=${arrondissementID}`,
      )
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )
