import { INIT_STATISTIC_STATE } from '../../constants/statistic'
import {
  StatisticGraph,
  StatisticInterval,
  StatisticOverview,
  StatisticState,
} from '../../interfaces/statistic'
import { typedAction } from '../utils'

const initialState: StatisticState = { ...INIT_STATISTIC_STATE }

export const getStatisticOverviewSaga = () =>
  typedAction('STATISTIC/GET_OVERVIEW_SAGA')
export type GetStatisticOverviewSaga = typeof getStatisticOverviewSaga

export const setStatisticOverview = (payload: StatisticOverview) =>
  typedAction('STATISTIC/SET_OVERVIEW', payload)
export type SetStatisticOverview = typeof setStatisticOverview

export const getStatisticIntervalSaga = () =>
  typedAction('STATISTIC/GET_INTERVAL_SAGA')
export type GetStatisticIntervalSaga = typeof getStatisticIntervalSaga

export const setStatisticInterval = (payload: StatisticInterval) =>
  typedAction('STATISTIC/SET_INTERVAL', payload)
export type SetStatisticInterval = typeof setStatisticInterval

export const getStaticGraphSaga = () => typedAction('STATISTIC/GET_GRAPH_SAGA')
export type GetStaticGraphSaga = typeof getStaticGraphSaga

export const setStatisticGraph = (payload: StatisticGraph[]) =>
  typedAction('STATISTIC/SET_GRAPH', payload)
export type SetStatisticGraph = typeof setStatisticGraph

// Actions type
type StatisticAction = ReturnType<
  SetStatisticInterval | SetStatisticOverview | SetStatisticGraph
>

// Reducer
export const StatisticReducer = (
  state = initialState,
  action: StatisticAction,
): StatisticState => {
  switch (action.type) {
    case 'STATISTIC/SET_INTERVAL':
      return {
        ...state,
        interval: {
          ...action.payload,
        },
      }

    case 'STATISTIC/SET_OVERVIEW':
      return {
        ...state,
        overview: {
          ...action.payload,
        },
      }
    case 'STATISTIC/SET_GRAPH':
      return {
        ...state,
        graph: action.payload,
      }

    default:
      return state
  }
}
