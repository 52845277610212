export const isValidEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const isValidPhoneNumber = (phoneNumber: string) => {
  const trimed = phoneNumber.replace(/ /g, '')
  if (trimed.length < 10 || trimed.length > 16) {
    return false
  }
  const re = /(^0|^\+{1})(?:[0-9]?){10,14}[0-9]$/
  return re.test(String(trimed).toLowerCase())
}

export const isValidNumber = (stringToCheck: string) => {
  if (!stringToCheck.length) {
    return false
  }
  const re = /^\d+$/
  return re.test(String(stringToCheck).toLowerCase())
}
