import { call, put, select, takeEvery } from 'redux-saga/effects'
import { MessageTypeEnum } from '../../enum/message'
import { DocumentsListArgs } from '../../interfaces/formData'
import { SubmitFormState } from '../../interfaces/submitForm'
import { formatSubmitFormDocumentsList } from '../../utils/formData'
import {
  getRolesOption,
  getTypeBailleurItems,
  getTypeContractItems,
} from '../../utils/submitForm'
import API from '../api'
import {
  setAllAddressesAndFokontany,
  setAllArrondissement,
  setPersonRoles,
  setSubmitFormDocList,
  setTypeBailleur,
  setTypeContract,
} from '../modules/formData'
import { displayGeneralLoading } from '../modules/loading'
import { displayMessage } from '../modules/message'
import { RootState } from '../store'

const getSubmitFormState = (state: RootState) => state.submitForm

function* getPersonRoleSE() {
  try {
    yield put(displayGeneralLoading(true))
    const data = yield call(API.formData.getPersonRoles)
    const roleOptions = getRolesOption(data.roles.role)
    // console.log('!!', roleOptions)
    yield put(setPersonRoles(roleOptions))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* getPersonRoleWatcher() {
  yield takeEvery('FORM_DATA/GET_PERSON_ROLES_SAGA', getPersonRoleSE)
}

function* getTypeBailleurSE() {
  try {
    yield put(displayGeneralLoading(true))
    const data = yield call(API.formData.getTypeBailleur)
    const typeBailleurItems = getTypeBailleurItems(
      data.listeTypeBailleurs.typeBailleur,
    )
    yield put(setTypeBailleur(typeBailleurItems))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* getTypeBailleurWatcher() {
  yield takeEvery('FORM_DATA/GET_TYPE_BAILLEUR_SAGA', getTypeBailleurSE)
}

function* getTypeContractSE() {
  try {
    yield put(displayGeneralLoading(true))
    const data = yield call(API.formData.getTypeContrat)
    const typeContractItems = getTypeContractItems(
      data.listeTypeContrats.typeContrat,
    )
    yield put(setTypeContract(typeContractItems))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* getTypeContractWatcher() {
  yield takeEvery('FORM_DATA/GET_TYPE_CONTRACT_SAGA', getTypeContractSE)
}

function* getSubmitFormDocListSE() {
  try {
    const submitFormState: SubmitFormState = yield select(getSubmitFormState)
    const {
      identity: { role },
      companyIdentity: {
        statusForm,
        logicalSharholderNb,
        leadersNb,
        isImportActivity,
        isIndustrialActivity,
        isWholesalerActivity,
        isOtherActivity,
      },
      companySiege: { bailleurType, contractType },
    } = submitFormState
    const args: DocumentsListArgs = {
      idFormeJuridique: +statusForm,
      idTypeBailleur: +bailleurType,
      idTypeContrat: +contractType,
      nombreAssociePersMorale: +logicalSharholderNb.replace(/ /g, ''),
      nombreDirigeant: +leadersNb.replace(/ /g, ''),
      activite:
        +isImportActivity === 1 ||
        +isIndustrialActivity === 1 ||
        +isWholesalerActivity === 1
          ? 1
          : 0,
      activitegrossiste:
        +isWholesalerActivity === 1 || +isOtherActivity === 1 ? 1 : 0,
      // mandataire: +role === 4 ? 1 : 0, // mandataire was separated to physical and company
      mandataire: +role === 11 || +role === 12 ? 1 : 0,
    }

    yield put(displayGeneralLoading(true))
    const data = yield call(API.formData.getSumitFormDocList, args)
    // console.log('!! data ', data)
    const formattedList = formatSubmitFormDocumentsList(data, args)
    // console.log('!! formattedList ', formattedList)
    yield put(setSubmitFormDocList(formattedList))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* getSubmitFormDocListWatcher() {
  yield takeEvery(
    'FORM_DATA/GET_SUBMIT_FORM_DOC_LIST_SAGA',
    getSubmitFormDocListSE,
  )
}

function* getAllAddressesSE() {
  try {
    const data = yield call(API.formData.getAllAddresses)
    yield put(setAllAddressesAndFokontany(data))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  }
}

export function* getAllAddressesWatcher() {
  yield takeEvery('FORM_DATA/GET_ALL_ADDRESSES_SAGA', getAllAddressesSE)
}

function* getAllArrondissementSE() {
  try {
    const data = yield call(API.formData.getAllArrondissement)
    yield put(setAllArrondissement(data))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  }
}

export function* getAllArrondissementWatcher() {
  yield takeEvery(
    'FORM_DATA/GET_ALL_ARRONDISSEMENT_SAGA',
    getAllArrondissementSE,
  )
}
