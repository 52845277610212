import { AppBar } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import HeaderBanner from '../../components/advertising/headerBanner'
import useHeader from '../../hooks/layout/useHeader'
import { MenuLinkItem } from '../../interfaces/common'
import { RootState } from '../../redux/store'
import { getInternationalizedValue } from '../../utils/common'
import DesktopHeader from './desktopHeader'
import MobileHeader from './mobileHeader'

const useStyles = makeStyles(() => ({
  header: {
    '@media (max-width: 960px)': {
      paddingLeft: 0,
      height: 70,
    },
    backgroundColor: '#F9FAFB',
    color: '#111827',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    height: 107,
  },
  marginTopForAppBar: {
    marginTop: 107,
    '@media (max-width: 960px)': {
      marginTop: 70,
    },
  },
}))

const Header = () => {
  const { header, marginTopForAppBar } = useStyles()

  const [state, setState] = useState({
    isMobileView: false,
    isDrawerOpen: false,
  })

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState({ ...state, isMobileView: true })
        : setState({ ...state, isMobileView: false })
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  // const dispatch = useDispatch()

  // const handleToggleTheme = () => {
  //   dispatch(toggleTheme())
  // }

  const { language } = useSelector((state: RootState) => state.app)

  const data = useHeader()
  const site_name = getInternationalizedValue(data, 'site_name', language)
  const ressource = getInternationalizedValue(data, 'ressource', language)
  const home = getInternationalizedValue(data, 'home', language)
  const connexion = getInternationalizedValue(data, 'connexion', language)
  const signUp = getInternationalizedValue(data, 'signup', language)

  const menuItems: MenuLinkItem[] = [
    {
      label: home,
      path: '/',
    },
    {
      label: ressource,
      path: '/ressources',
    },
  ]

  const handleDrawerToggle = () => {
    setState({ ...state, isDrawerOpen: !state.isDrawerOpen })
  }

  return (
    <>
      <AppBar className={header} elevation={0}>
        {state.isMobileView ? (
          <MobileHeader
            siteName={site_name}
            menuItems={menuItems}
            isDrawerOpen={state.isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            connexion={connexion}
            signup={signUp}
          />
        ) : (
          <DesktopHeader
            siteName={site_name}
            menuItems={menuItems}
            connexion={connexion}
            signup={signUp}
          />
        )}
      </AppBar>
      {/* Box bellow is a tricks to prevent part of layout body hidden bellow AppBar */}
      <Box className={marginTopForAppBar}>&nbsp;</Box>
      <HeaderBanner isTop={true} />
    </>
  )
}

export default Header
