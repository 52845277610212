import {
  getArrondissementByCommune,
  getCommuneByDistrict,
  getDistrictByRegion,
  getProvince,
  getQuartierByArrondissement,
  getRegionByProvince,
} from './address'
import { getWorldTime, getCurrentIP } from './common'
import {
  getAllAddresses,
  getAllArrondissement,
  getPersonRoles,
  getSumitFormDocList,
  getTypeBailleur,
  getTypeContrat,
} from './formData'
import { sendMail } from './mail'
import {
  getStatisticGraphApi,
  getStatisticIntervalApi,
  getStatisticOverviewApi,
} from './statistic'
import {
  checkCodeIntoMailLink,
  getInformationByLink,
  getPreviouslyUploadedDoc,
  sendSubmitForm,
  sendSubmitFormDocument,
  submitFormSaveLink,
  updateCompany,
  updateCompanySiege,
  updateCompanySiegeAddress,
  updateFolderStatus,
  updatePersonAddress,
  updatePersonIdentity,
} from './submitForm'

const API = {
  address: {
    getProvince,
    getRegionByProvince,
    getDistrictByRegion,
    getCommuneByDistrict,
    getArrondissementByCommune,
    getQuartierByArrondissement,
  },
  formData: {
    getPersonRoles,
    getTypeBailleur,
    getTypeContrat,
    getSumitFormDocList,
    getAllAddresses,
    getAllArrondissement,
  },
  submitForm: {
    sendSubmitForm,
    sendSubmitFormDocument,
    submitFormSaveLink,
    checkCodeIntoMailLink,
    getInformationByLink,
    updatePersonIdentity,
    updatePersonAddress,
    updateCompany,
    updateCompanySiege,
    updateCompanySiegeAddress,
    updateFolderStatus,
    getPreviouslyUploadedDoc,
  },
  mail: {
    sendMail,
  },
  common: {
    getWorldTime,
    getCurrentIP,
  },
  statistic: {
    getStatisticOverviewApi,
    getStatisticIntervalApi,
    getStatisticGraphApi,
  },
}

export default API
