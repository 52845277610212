import axios, { AxiosResponse } from 'axios'
import { API_SERVER_URL } from '../../constants/common'
import { MessageErrorEnum } from '../../enum/message'
import { MailSendPayload } from '../../interfaces/mail'
const MAIL_ROOT = 'gateway/mail'

export const sendMail = (
  payload: MailSendPayload,
): Promise<AxiosResponse<any>> =>
  new Promise((resolve, reject) =>
    axios
      .post(`${API_SERVER_URL}/${MAIL_ROOT}/send`, payload)
      .then(res => {
        if (res.data) {
          if (res.data.error) {
            reject(new Error(MessageErrorEnum.MAIL_NOT_SENT_ERROR))
          }
          resolve(res.data)
        }
        reject(new Error(MessageErrorEnum.SERVER_ERROR))
      })
      .catch(e => {
        reject(new Error(MessageErrorEnum.NETWORK_ERROR))
      }),
  )
