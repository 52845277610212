import { SelectItem } from '../interfaces/common'
import {
  AllAddresses,
  AllArrondissementData,
  DocumentsListArgs,
  SubmitFormDocItem,
} from '../interfaces/formData'

export const formatSubmitFormDocumentsList = (
  data: any[],
  args: DocumentsListArgs,
): SubmitFormDocItem[] => {
  const {
    nombreDirigeant: nbLeader,
    nombreAssociePersMorale: nbShareholder,
  } = args
  const results: SubmitFormDocItem[] = []
  if (!data) {
    return []
  }
  data.forEach(d => {
    if (d && d.owner && d.inboundPayload) {
      if (d.inboundPayload.listeDocuments) {
        const liste: any[] = d.inboundPayload.listeDocuments.listeDocument || []

        liste.forEach(l => {
          if (d.owner === 'Associate' || d.owner === 'Leader') {
            const multiplier = d.owner === 'Leader' ? nbLeader : nbShareholder
            results.push({
              nom: l.nomDocument,
              nbDeposer: l.paramDeposer
                ? +l.nbDeposer * multiplier
                : +l.nbDeposer,
              nbUploader: l.paramUpload
                ? +l.nbUploader * multiplier
                : +l.nbUploader,
            })
          } else {
            results.push({
              nom: l.nomDocument,
              nbDeposer: +l.nbDeposer,
              nbUploader: +l.nbUploader,
            })
          }
        })
      }
    }
  })

  // Do not return (unecessary display) if nbDeposer and nbUploader === 0
  return results.filter(r => r.nbDeposer !== 0 && r.nbUploader !== 0)
}

interface FormattedAllAddresses {
  allAddresses: AllAddresses[]
  allFokontany: SelectItem[]
}
export const formatAllAddresses = (data: any[]): FormattedAllAddresses => {
  const allAddresses: AllAddresses[] = []
  const allFokontany: SelectItem[] = []

  for (const d of data) {
    const { pId, rId, dId, cId, bId, fId, c, f } = d
    if (pId && rId && dId && cId && bId && fId && c && f) {
      allAddresses.push({
        idProvince: pId,
        idRegion: rId,
        idDistrict: dId,
        idCommune: cId,
        idArrondissement: bId,
        idFokontany: fId,
        commune_name: c,
        fokontany_name: f,
      })
      allFokontany.push({ label: `${f} / ${c}`, value: fId })
    }
  }
  // allFokontany.unshift({ label: '', value: '' })

  return { allAddresses, allFokontany }
}

interface FormattedAllArrondissement {
  allArrondissement: AllArrondissementData[]
  arrondissementItems: SelectItem[]
}

export const formatAllArrondissement = (
  data: any[],
): FormattedAllArrondissement => {
  const allArrondissement: AllArrondissementData[] = []
  const arrondissementItems: SelectItem[] = []

  for (const d of data) {
    const { pId, rId, dId, cId, bId, b, d: nameDistrict } = d
    if (pId && rId && dId && cId && bId && b && nameDistrict) {
      allArrondissement.push({
        idProvince: pId,
        idRegion: rId,
        idDistrict: dId,
        idCommune: cId,
        idArrondissement: bId,
        nameArrondissement: b,
        nameDistrict,
      })
      arrondissementItems.push({ label: `${b} / ${nameDistrict}`, value: bId })
    }
  }
  // allFokontany.unshift({ label: '', value: '' })

  const sortedItemsByDistrict = arrondissementItems.sort((a, b) => {
    const aArrondissement = a.label.split('/')[0]
    const aDistrict = a.label.split('/')[1]
    const bArrondissement = a.label.split('/')[0]
    const bDistrict = a.label.split('/')[1]
    const aFull = `${aDistrict}${aArrondissement}`
    const bFull = `${bDistrict}${bArrondissement}`
    if (aFull < bFull) {
      return -1
    } else if (bFull < aFull) {
      return 1
    }
    return 0
  })

  // return { allArrondissement, arrondissementItems };
  return { allArrondissement, arrondissementItems: sortedItemsByDistrict }
}
