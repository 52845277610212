import { typedAction } from '../utils'
import { MessageTypeEnum } from '../../enum/message'
import { MessageState } from '../../interfaces/message'

const initialState: MessageState = {
  isOpen: false,
  message: '',
  type: MessageTypeEnum.SUCCESS,
}

// Actions creator
export const displayMessage = (message: string, type: MessageTypeEnum) =>
  typedAction('MESSAGE/DISPLAY', { message, type })
export type DisplayMessage = typeof displayMessage

export const closeMessage = () => typedAction('MESSAGE/CLOSE')
export type CloseMessage = typeof closeMessage

// Actions type
type MessageAction = ReturnType<DisplayMessage | CloseMessage>

// Reducer
export const MessageReducer = (
  state = initialState,
  action: MessageAction,
): MessageState => {
  switch (action.type) {
    case 'MESSAGE/DISPLAY':
      return { ...state, isOpen: true, ...action.payload }
    case 'MESSAGE/CLOSE':
      return { ...state, isOpen: false }
    default:
      return state
  }
}
