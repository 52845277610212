import format from 'date-fns/format'
import isFuture from 'date-fns/isFuture'
import isValid from 'date-fns/isValid'
import { enUS, fr } from 'date-fns/locale'
import { LanguagesEnum } from '../enum/common'

export const getInternationalizedValue = (
  data: any,
  prefix: string,
  language = 'fr',
): string => {
  let value = ''

  try {
    Object.keys(data).forEach(key => {
      if (key === `${prefix}_${language}`) {
        value = data[key]
      }
    })
  } catch (e) {}

  return value
}

export const markStringField = (
  data: any,
  pattern: string,
  prefix: string,
  language = 'fr',
  isText = true,
): string => {
  let value = ''

  try {
    Object.keys(data).forEach(key => {
      if (key === `${prefix}_${language}`) {
        if (isText) {
          value = data[key].replace(
            new RegExp(pattern, 'gi'),
            (match: any) => `<mark>${match}</mark>`,
          )
        } else {
          value = data[key].replace(
            new RegExp(pattern, 'gi'),
            (match: any) => `<mark>${match}</mark>`,
          )
        }
      }
    })
  } catch (e) {}

  return value
}

export const createMarkup = (html: string): any => ({ __html: html })

export const getInternationalizedDate = (
  date: string,
  language = 'fr',
): string => {
  switch (language) {
    case LanguagesEnum.FR:
      return format(new Date(date), 'MMMM yyy', { locale: fr })

    case LanguagesEnum.EN:
      return format(new Date(date), 'MMMM yyy', { locale: enUS })

    case LanguagesEnum.MG:
      // TODO: implement custom locale for MG
      return format(new Date(date), 'MMMM yyy', { locale: fr })
    default:
      return format(new Date(date), 'MMMM yyy', { locale: fr })
  }
}

export const formatDateHour = (date: Date | string): string => {
  if (typeof date === 'string') {
    return format(new Date(date), 'EEEE dd/MM/yyyy à HH:mm', { locale: fr })
  }
  return format(date, 'EEEE dd/MM/yyyy à HH:mm', { locale: fr })
}

export const getArticlePath = (articleId: number, prefix = 'Articles_') =>
  `/${prefix}${articleId}`

export const addPrefix = (id: string, prefix: string) => `${prefix}${id}`

export const getNumberId = (stringId: string): number => {
  let result = 0
  const splitted = stringId.split('_')
  if (splitted.length > 1) {
    try {
      result = +splitted[splitted.length - 1]
    } catch {}
  }

  return result
}

export const checkDateFormat = (
  stringDate: string,
  canBeFuture = true,
): boolean => {
  const currentDate = new Date(stringDate)
  const isFormatValid = isValid(currentDate)
  const isPeriodOk = canBeFuture ? true : !isFuture(currentDate)

  return isFormatValid && isPeriodOk
}

export const checkStringLength = (
  stringData: string,
  min = 3,
  max = 250,
): boolean => stringData.trim().length >= min && stringData.trim().length < max

export const stringToNumber = (
  stringNumber: string | null | undefined,
): number => {
  if (!stringNumber || Number.isNaN(stringNumber)) {
    return 0
  }
  return +stringNumber
}

export const formatDateFromBack = (
  dateFromBack: string | null | undefined,
): string => {
  let statusDate = new Date().toString()
  // dateStatut from back is like this: 2021-02-12+00:00
  const dateArray = dateFromBack ? dateFromBack.split('+') : []
  if (dateArray.length > 0) {
    statusDate = new Date(dateArray[0]).toString()
  }
  return statusDate
}

// Generate folder number by timestamp from back
// We can retrieve original date by adding "1" at begining, and replace 4 latest chars by "0000"
export const generateFolderNumber = (currentTimestamp: number): string => {
  const adjusted = currentTimestamp.toString().slice(1, 9) // Get only 8 variables chars
  const parts: string[] = []
  for (let i = 0; i < adjusted.length; i += 4) {
    parts.push(adjusted.slice(i, i + 4))
  }
  // Add current millisecond
  let millisecond = new Date().getMilliseconds().toString()
  millisecond =
    millisecond.length < 3
      ? `${millisecond}0`
      : millisecond.length < 2
      ? `${millisecond}10`
      : millisecond
  const latestNumber = Math.ceil(Math.random() * 10)
  const lastPart = `${millisecond}${latestNumber}`.slice(0, 4)
  parts.push(lastPart)
  return parts.join('-')
}

export const formatNumber = (nb: number, thousands = ' ') => {
  try {
    const negativeSign = nb < 0 ? '-' : ''

    const strNb = `${nb}`

    const j = strNb.length > 3 ? strNb.length % 3 : 0

    return (
      negativeSign +
      (j ? strNb.substr(0, j) + thousands : '') +
      strNb.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
    )
  } catch (e) {
    return ''
  }
}

export const getArrayFromString = (initialString: string, separator = '/') =>
  initialString.split(separator).filter(m => Boolean(m.trim().length))

export const removeSpaceOfNumber = (value: string) => +value.replace(/ /g, '')

export const readeableSecond = (second: number) => {
  if (second > 3600 * 24) {
    return `${Math.floor(second / 3600 / 24)} j`
  }

  if (second > 3600) {
    return `${Math.floor(second / 3600)} h`
  }

  if (second > 60) {
    return `${Math.floor(second / 60)} m`
  }

  return second > 0 ? `${Math.floor(second)} s` : '0 s'
}

export const readableMinute = (minutes: number) => {
  if (minutes === 0) {
    return '0 s'
  }
  if (minutes < 1) {
    return `${Math.round(minutes * 60)} s`
  }
  if (minutes < 60) {
    return `${Math.round(minutes)} m`
  }
  if (minutes > 60 * 24) {
    return `${Math.round(minutes / (60 * 24))} j`
  }
  if (minutes > 60) {
    return `${Math.round(minutes / 60)} h`
  }
  return ''
}
