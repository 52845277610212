import { Box, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import egmLogo from '../../../../images/logoEGM.png'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const EGMLogos = () => {
  const { container } = useStyles()

  const handleGoTo = () => {
    if (typeof window !== 'undefined') {
      window.open('https://digital.gov.mg/')
    }
  }

  return (
    <Box className={container}>
      <img src={egmLogo} alt="egm-logo" height="30px" onClick={handleGoTo} />
    </Box>
  )
}

export default EGMLogos
