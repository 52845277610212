import {
  INIT_COMPANY_IDENTITY,
  INIT_COMPANY_SIEGE,
  INIT_IDENTITY,
  INIT_IDS_FOR_UPDATE,
} from '../../constants/submitForm'
import { SubmitFormAddressEnum } from '../../enum/submitForm'
import { FileStore, SelectItem } from '../../interfaces/common'
import { FormProps } from '../../interfaces/form'
import {
  CheckCodeIntoMailLinkArgs,
  GetInformationByLinkArgs,
  HandleNoDocumentArgs,
  SaveDocumentInformationArgs,
  SendMailSaveLinkArgs,
  SubmitFormAddressArgs,
  SubmitFormAddressArrondissementArgs,
  SubmitFormAddressMinArgs,
  SubmitFormAllDocumentsArgs,
  SubmitFormDocumentsArgs,
  SubmitFormLoadArgs,
  SubmitFormSaveLinkArgs,
  SubmitFormState,
  UploadedDocumentsInfo,
  UserFolderInfo,
} from '../../interfaces/submitForm'
import { typedAction } from '../utils'

const INITIAL_STATE = {
  step: 0,
  nomacSearch: [],
  identity: { ...INIT_IDENTITY },
  companyIdentity: { ...INIT_COMPANY_IDENTITY },
  companySiege: { ...INIT_COMPANY_SIEGE },
  files: [],
  folderInfo: { id: 0, number: '', idSociete: 0, idStatutDossier: 0 },
  isSaving: false,
  nbTriedUploadedFiles: 0,
  notUploadedFiles: [],
  linkInfo: {
    type: '',
    pod: '',
  },
  idsForUpdate: { ...INIT_IDS_FOR_UPDATE },
  uploadedDocumentsList: [],
}

const initialState: SubmitFormState = { ...INITIAL_STATE }

// Actions creator
export const setSubmitFormStep = (isAdd: boolean) =>
  typedAction('SUBMIT_FORM/SET_STEP', isAdd)
export type SetSubmitFormStep = typeof setSubmitFormStep

export const resetSubmitFormStep = () => typedAction('SUBMIT_FORM/RESET_STEP')
export type ResetSubmitFormStep = typeof resetSubmitFormStep

export const setSubmitFormAddress = (payload: SubmitFormAddressArgs) =>
  typedAction('SUBMIT_FORM/SET_SUBMIT_FORM_ADDRESS', payload)
export type SetSubmitFormAddress = typeof setSubmitFormAddress

// setSubmitFormAddressMin: deprecated (used for fokontany)
export const setSubmitFormAddressMin = (payload: SubmitFormAddressMinArgs) =>
  typedAction('SUBMIT_FORM/SET_SUBMIT_FORM_ADDRESS_MIN', payload)
export type SetSubmitFormAddressMin = typeof setSubmitFormAddressMin

export const setSubmitFormAddressArrondissement = (
  payload: SubmitFormAddressArrondissementArgs,
) => typedAction('SUBMIT_FORM/SET_SUBMIT_FORM_ADDRESS_ARRONDISSEMENT', payload)
export type SetSubmitFormAddressArrondissement = typeof setSubmitFormAddressArrondissement

export const setIdentityForm = (payload: FormProps) =>
  typedAction('SUBMIT_FORM/SET_IDENTITY_FORM', payload)
export type SetIdentityForm = typeof setIdentityForm

export const setCompanyIdentityForm = (payload: FormProps) =>
  typedAction('SUBMIT_FORM/SET_COMPANY_IDENTITY_FORM', payload)
export type SetCompanyIdentityForm = typeof setCompanyIdentityForm

export const setCompanySiegeForm = (payload: FormProps) =>
  typedAction('SUBMIT_FORM/SET_COMPANY_SIEGE_FORM', payload)
export type SetCompanySiegeForm = typeof setCompanySiegeForm

export const resetSubmitForm = () =>
  typedAction('SUBMIT_FORM/RESET_SUBMIT_FORM')
export type ResetSubmitForm = typeof resetSubmitForm

export const setNomacSearch = (nomacData: SelectItem[]) =>
  typedAction('SUBMIT_FORM/SET_NOMAC_SEARCH', nomacData)
export type SetNomacSearch = typeof setNomacSearch

export const sendSubmitFormSaga = () => typedAction('SUBMIT_FORM/SEND_DATA')
export type SendSubmitFormSaga = typeof sendSubmitFormSaga

export const addFilesToSubmitForm = (files: FileStore[]) =>
  typedAction('SUBMIT_FORM/ADD_FILES', files)
export type AddFilesToSubmitForm = typeof addFilesToSubmitForm

export const resetFilesToSubmitForm = () =>
  typedAction('SUBMIT_FORM/RESET_FILES')
export type ResetFilesToSubmitForm = typeof resetFilesToSubmitForm

export const removeFileToSubmitForm = (fileName: string) =>
  typedAction('SUBMIT_FORM/REMOVE_FILE', fileName)
export type RemoveFileToSubmitForm = typeof removeFileToSubmitForm

export const saveDocumentInfoSaga = (payload: SaveDocumentInformationArgs) =>
  typedAction('SUBMIT_FORM/SAVE_DOCUMENT_INFO', payload)
export type SaveDocumentInfoSaga = typeof saveDocumentInfoSaga

export const sendSubmitFormDocumentSaga = (payload: SubmitFormDocumentsArgs) =>
  typedAction('SUBMIT_FORM/SEND_DOCUMENT', payload)
export type SendSubmitFormDocumentSaga = typeof sendSubmitFormDocumentSaga

export const sendSubmitFormAllDocumentSaga = (
  payload: SubmitFormAllDocumentsArgs,
) => typedAction('SUBMIT_FORM/SEND_ALL_DOCUMENTS', payload)
export type SendSubmitFormAllDocumentSaga = typeof sendSubmitFormAllDocumentSaga

export const setFolderInfo = (payload: UserFolderInfo) =>
  typedAction('SUBMIT_FORM/SET_FOLDER_INFO', payload)
export type SetFolderInfo = typeof setFolderInfo

export const setIsSavingSubmitForm = (isSaving: boolean) =>
  typedAction('SUBMIT_FORM/SET_IS_SAVING', isSaving)
export type SetIsSavingSubmitForm = typeof setIsSavingSubmitForm

export const addNotUploadedFiles = (filename: string) =>
  typedAction('SUBMIT_FORM/ADD_NOT_UPLOADED_FILES', filename)
export type AddNotUploadedFiles = typeof addNotUploadedFiles

export const incNbTriedUploadedFiles = () =>
  typedAction('SUBMIT_FORM/INC_TRIED_TO_UPLOAD_FILES')
export type IncNbTriedUploadedFiles = typeof incNbTriedUploadedFiles

export const submitFormSaveLinkSaga = (payload: SubmitFormSaveLinkArgs) =>
  typedAction('SUBMIT_FORM/SAVE_LINK', payload)
export type SubmitFormSaveLinkSaga = typeof submitFormSaveLinkSaga

export const sendMailSaveLinkSaga = (payload: SendMailSaveLinkArgs) =>
  typedAction('SUBMIT_FORM/SEND_MAIL_SAVE_LINK', payload)
export type SendMailSaveLinkSaga = typeof sendMailSaveLinkSaga

export const checkLinkFromMailSaga = (payload: CheckCodeIntoMailLinkArgs) =>
  typedAction('SUBMIT_FORM/CHECK_LINK_FORM_MAIL', payload)
export type CheckLinkFromMailSaga = typeof checkLinkFromMailSaga

export const setLinkInfo = (payload: CheckCodeIntoMailLinkArgs) =>
  typedAction('SUBMIT_FORM/SET_LINK_INFO', payload)
export type SetLinkInfo = typeof setLinkInfo

export const getInformationByLinkSaga = (payload: GetInformationByLinkArgs) =>
  typedAction('SUBMIT_FORM/GET_INFO_BY_LINK', payload)
export type GetInformationByLinkSaga = typeof getInformationByLinkSaga

export const loadSubmitFormDataIntoStore = (payload: SubmitFormLoadArgs) =>
  typedAction('SUBMIT_FORM/LOAD_SUBMIT_FORM_DATA_INTO_STORE', payload)
export type LoadSubmitFormDataIntoStore = typeof loadSubmitFormDataIntoStore

export const loadUploadedDocumentsIntoStoreSaga = (folderInfo: number) =>
  typedAction('SUBMIT_FORM/LOAD_UPLOADED_FILES_INTO_STORE_SAGA', folderInfo)
export type LoadUploadedDocumentsIntoStoreSaga = typeof loadUploadedDocumentsIntoStoreSaga

export const loadUploadedDocumentsIntoStore = (
  payload: UploadedDocumentsInfo[],
) => typedAction('SUBMIT_FORM/LOAD_UPLOADED_FILES_INTO_STORE', payload)
export type LoadUploadedDocumentsIntoStore = typeof loadUploadedDocumentsIntoStore

// Update person identity and address
export const updatePersonSaga = () => typedAction('SUBMIT_FORM/UPDATE_PERSON')
export type UpdatePersonSaga = typeof updatePersonSaga

// Update company identity and address
export const updateCompanySaga = () => typedAction('SUBMIT_FORM/UPDATE_COMPANY')
export type UpdateCompanySaga = typeof updateCompanySaga

// Update company siege and address
export const updateCompanySiegeSaga = () =>
  typedAction('SUBMIT_FORM/UPDATE_COMPANY_SIEGE')
export type UpdateCompanySiegeSaga = typeof updateCompanySiegeSaga

// Send mail and update folder's status
export const handleNoDocumentSaga = (payload: HandleNoDocumentArgs) =>
  typedAction('SUBMIT_FORM/HANDLE_NO_DOCUMENT_SAGA', payload)
export type HandleNoDocumentSaga = typeof handleNoDocumentSaga

// Actions type
type SubmitFormAction = ReturnType<
  | SetSubmitFormStep
  | ResetSubmitFormStep
  | SetSubmitFormAddress
  | SetSubmitFormAddressMin
  | SetIdentityForm
  | SetCompanyIdentityForm
  | SetCompanySiegeForm
  | ResetSubmitForm
  | SetNomacSearch
  | AddFilesToSubmitForm
  | ResetFilesToSubmitForm
  | RemoveFileToSubmitForm
  | SetFolderInfo
  | SetIsSavingSubmitForm
  | AddNotUploadedFiles
  | SendSubmitFormDocumentSaga
  | IncNbTriedUploadedFiles
  | SaveDocumentInfoSaga
  | SetLinkInfo
  | LoadSubmitFormDataIntoStore
  | LoadUploadedDocumentsIntoStore
  | SetSubmitFormAddressArrondissement
>

// Reducer
export const SubmitFormReducer = (
  state = initialState,
  action: SubmitFormAction,
): SubmitFormState => {
  switch (action.type) {
    case 'SUBMIT_FORM/SET_STEP':
      const isAdd = action.payload
      const newStep = isAdd ? state.step + 1 : state.step - 1
      return { ...state, step: newStep }
    case 'SUBMIT_FORM/RESET_STEP':
      return { ...state, step: 0 }

    case 'SUBMIT_FORM/SET_NOMAC_SEARCH':
      return {
        ...state,
        nomacSearch: [...action.payload],
      }

    case 'SUBMIT_FORM/SET_SUBMIT_FORM_ADDRESS':
      const { addressToChange, fieldData } = action.payload
      const name = fieldData.name
      const value = fieldData.value as string
      let currentAddress =
        addressToChange === SubmitFormAddressEnum.IDENTITY
          ? state.identity.address
          : state.companySiege.address

      switch (name) {
        case 'province':
          // Reset all fields bellow 'province': region, district, commune, ...
          currentAddress = {
            ...currentAddress,
            province: value,
            region: '',
            district: '',
            commune: '',
            arrondissement: '',
            quartier: '',
          }
          break
        case 'region':
          currentAddress = {
            ...currentAddress,
            region: value,
            district: '',
            commune: '',
            arrondissement: '',
            quartier: '',
          }
          break
        case 'district':
          // Reset all fields bellow 'district': commune, arrondissement, quartier, ...
          currentAddress = {
            ...currentAddress,
            district: value,
            commune: '',
            arrondissement: '',
            quartier: '',
          }
          break
        case 'commune':
          // Reset all fields bellow 'commune': arrondissement, quartier, ...
          currentAddress = {
            ...currentAddress,
            commune: value,
            arrondissement: '',
            quartier: '',
          }
          break
        case 'arrondissement':
          // Reset 'quartier'
          currentAddress = {
            ...currentAddress,
            arrondissement: value,
            quartier: '',
          }
        default:
          currentAddress = { ...currentAddress, [name]: value }
      }

      if (addressToChange === SubmitFormAddressEnum.IDENTITY) {
        return {
          ...state,
          identity: { ...state.identity, address: { ...currentAddress } },
        }
      } else if (addressToChange === SubmitFormAddressEnum.COMPANY) {
        return {
          ...state,
          companySiege: {
            ...state.companySiege,
            address: { ...currentAddress },
          },
        }
      } else {
        return state
      }

    case 'SUBMIT_FORM/SET_SUBMIT_FORM_ADDRESS_MIN':
      const {
        allAddresses,
        addressToChange: toChange,
        fieldData: fieldsPayload,
      } = action.payload
      const fieldsName = fieldsPayload.name
      const fieldsValue = fieldsPayload.value as string
      let currentAddressValue =
        toChange === SubmitFormAddressEnum.IDENTITY
          ? state.identity.address
          : state.companySiege.address

      switch (fieldsName) {
        case 'quartier':
          const selectedAddress = allAddresses.find(
            a => a.idFokontany === fieldsValue,
          )
          if (selectedAddress) {
            const {
              idProvince,
              idRegion,
              idDistrict,
              idCommune,
              idArrondissement,
              idFokontany,
            } = selectedAddress
            currentAddressValue = {
              ...currentAddressValue,
              province: idProvince,
              region: idRegion,
              district: idDistrict,
              commune: idCommune,
              arrondissement: idArrondissement,
              quartier: idFokontany,
            }
          }
          break
        case 'localAddress':
          currentAddressValue = {
            ...currentAddressValue,
            localAddress: fieldsValue,
          }
          break
        default:
          break
      }

      if (toChange === SubmitFormAddressEnum.IDENTITY) {
        return {
          ...state,
          identity: { ...state.identity, address: { ...currentAddressValue } },
        }
      } else if (toChange === SubmitFormAddressEnum.COMPANY) {
        return {
          ...state,
          companySiege: {
            ...state.companySiege,
            address: { ...currentAddressValue },
          },
        }
      } else {
        return state
      }

    case 'SUBMIT_FORM/SET_SUBMIT_FORM_ADDRESS_ARRONDISSEMENT':
      const {
        arrondissementData,
        addressToChange: addrToChange,
        fieldData: fieldsNameValue,
      } = action.payload

      const fName = fieldsNameValue.name
      const fValue = fieldsNameValue.value as string
      let currentAddrValue =
        addrToChange === SubmitFormAddressEnum.IDENTITY
          ? state.identity.address
          : state.companySiege.address

      switch (fName) {
        case 'arrondissement':
          const selectedAddress = arrondissementData.find(
            a => a.idArrondissement === fValue,
          )
          if (selectedAddress) {
            const {
              idProvince,
              idRegion,
              idDistrict,
              idCommune,
              idArrondissement,
            } = selectedAddress
            currentAddrValue = {
              ...currentAddrValue,
              province: idProvince,
              region: idRegion,
              district: idDistrict,
              commune: idCommune,
              arrondissement: idArrondissement,
            }
          }
          break
        default:
          currentAddrValue = { ...currentAddrValue, [fName]: fValue }
      }

      if (addrToChange === SubmitFormAddressEnum.IDENTITY) {
        return {
          ...state,
          identity: { ...state.identity, address: { ...currentAddrValue } },
        }
      } else if (addrToChange === SubmitFormAddressEnum.COMPANY) {
        return {
          ...state,
          companySiege: {
            ...state.companySiege,
            address: { ...currentAddrValue },
          },
        }
      } else {
        return state
      }

    case 'SUBMIT_FORM/SET_IDENTITY_FORM':
      const identityFieldName = action.payload.name
      return {
        ...state,
        identity: {
          ...state.identity,
          [identityFieldName]: `${action.payload.value}`,
        },
      }

    case 'SUBMIT_FORM/SET_COMPANY_IDENTITY_FORM':
      const companyIdentityFieldName = action.payload.name
      return {
        ...state,
        companyIdentity: {
          ...state.companyIdentity,
          [companyIdentityFieldName]: `${action.payload.value}`,
        },
      }

    case 'SUBMIT_FORM/SET_COMPANY_SIEGE_FORM':
      const companySiegeFieldName = action.payload.name
      return {
        ...state,
        companySiege: {
          ...state.companySiege,
          [companySiegeFieldName]: `${action.payload.value}`,
        },
      }

    case 'SUBMIT_FORM/RESET_SUBMIT_FORM':
      return {
        ...INITIAL_STATE,
      }

    case 'SUBMIT_FORM/ADD_FILES':
      return {
        ...state,
        files: [...state.files, ...action.payload],
      }

    case 'SUBMIT_FORM/RESET_FILES':
      return {
        ...state,
        files: [],
        nbTriedUploadedFiles: 0,
        uploadedDocumentsList: [],
      }

    case 'SUBMIT_FORM/REMOVE_FILE':
      const fileName = action.payload
      return {
        ...state,
        files: [...state.files.filter(f => f.filename !== fileName)],
      }

    case 'SUBMIT_FORM/SET_FOLDER_INFO':
      return {
        ...state,
        folderInfo: { ...action.payload },
      }

    case 'SUBMIT_FORM/SET_IS_SAVING':
      return {
        ...state,
        isSaving: action.payload,
      }

    case 'SUBMIT_FORM/ADD_NOT_UPLOADED_FILES':
      return {
        ...state,
        notUploadedFiles: [...state.notUploadedFiles, action.payload],
      }

    case 'SUBMIT_FORM/INC_TRIED_TO_UPLOAD_FILES':
      return {
        ...state,
        nbTriedUploadedFiles: state.nbTriedUploadedFiles + 1,
      }

    case 'SUBMIT_FORM/SET_LINK_INFO':
      return {
        ...state,
        linkInfo: { ...action.payload },
      }

    case 'SUBMIT_FORM/LOAD_SUBMIT_FORM_DATA_INTO_STORE':
      return {
        ...state,
        ...action.payload,
      }

    case 'SUBMIT_FORM/LOAD_UPLOADED_FILES_INTO_STORE':
      if (!action.payload.length) {
        return state
      }
      return {
        ...state,
        uploadedDocumentsList: [...action.payload],
      }

    default:
      return state
  }
}
