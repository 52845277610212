const CONFIG = {
  server: {
    api: {
      ip: process.env.GATSBY_PROXY_URL || '', // All urls go to proxy which dispatch them
    },
    gateway: {
      publicKey: process.env.URL_KEY || '',
    },
  },
  mail: {
    contact: process.env.GATSBY_EDBM_CONTACT_MAIL || 'dgiedbm@gmail.com',
    agents: process.env.GATSBY_AGENT_MAILS || '',
  },
  siteUrl: process.env.GATSBY_SITE_URL || '',
  clientSpaceUrl: process.env.GATSBY_CLIENT_SPACE_URL || '',
}

export default CONFIG
