import { Paper } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { FC } from 'react'
import { MessageTypeEnum } from '../../../enum/message'
import { CloseMessage } from '../../../redux/modules/message'

interface IGlobalSnackbarProps {
  isOpen: boolean
  message: string
  type: MessageTypeEnum
  closeMessage: CloseMessage
}

const GlobalSnackbar: FC<IGlobalSnackbarProps> = props => {
  const { isOpen, message, type, closeMessage } = props

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={closeMessage}
    >
      <Paper
        style={{
          backgroundColor: type === MessageTypeEnum.SUCCESS ? '#179D75' : 'red',
          zIndex: 9,
          padding: 12,
          color: '#fff',
        }}
        elevation={4}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginRight={4}
        >
          <Typography variant="body2" style={{ textAlign: 'center' }}>
            {message}
          </Typography>
          <Box marginLeft={2} position="absolute" top={8} right={8}>
            <IconButton
              key="close"
              aria-label="Close"
              color="primary"
              onClick={closeMessage}
              size="small"
              style={{ color: '#fff' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Paper>
    </Snackbar>
  )
}

export default GlobalSnackbar
