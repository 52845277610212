import Box from '@material-ui/core/Box'
import { useLocation } from '@reach/router'
import React, { FC, useEffect, useState } from 'react'
import useAllHeaderAdvertising from '../../../hooks/advertisingBanners/useAllHeaderAdvertising'
import { getAdvertisingImageItems } from '../../../utils/advertisingBanner'
import PubCarousel from '../../common/pubCarousel'

interface HeaderBannerProps {
  isTop?: boolean
}

const HeaderBanner: FC<HeaderBannerProps> = props => {
  const { isTop } = props

  const data = useAllHeaderAdvertising()
  const { desktop, mobile } = getAdvertisingImageItems(data)
  // console.log('!!', desktop)

  const [state, setState] = useState({
    isMobileView: false,
    isDrawerOpen: false,
  })

  const location = useLocation()
  const isNotHome = !location.pathname.includes(
    process.env.GATSBY_PATH_PREFIX ?? '/',
  )
  const isNotHowTo = !location.pathname.includes('howTo')
  const shouldOnTop = isNotHome && isNotHowTo

  const displayBanner = (isTop && shouldOnTop) || (!isTop && !shouldOnTop)

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState({ ...state, isMobileView: true })
        : setState({ ...state, isMobileView: false })
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  return (
    <>
      {displayBanner ? (
        <>
          {state.isMobileView ? (
            <Box mt={2} mb={4}>
              <PubCarousel width={320} height={100} imagesData={mobile} />
            </Box>
          ) : (
            <Box mt={2} mb={4}>
              <PubCarousel width={728} height={90} imagesData={desktop} />
            </Box>
          )}
        </>
      ) : null}
    </>
  )
}

export default HeaderBanner
