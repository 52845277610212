import { Theme } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/styles'
import React, { ComponentProps, FC } from 'react'
import { useSelector } from 'react-redux'
import ScrollToTop from '../components/common/scrollToTop'
// import useSiteMetadata from '../hooks/useSiteMetadata'
import { RootState } from '../redux/store'
import themes from '../theme'
import Footer from './footer'
import Header from './header'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  main: {
    margin: `0px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
    width: '98%',
    maxWidth: 1440,
  },
}))

const LayoutComponent: FC = ({ children }) => {
  const classes = useStyles()
  // const { title } = useSiteMetadata()
  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.mainContainer}>
        <main className={classes.main}>{children}</main>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  )
}
const Layout: FC<ComponentProps<any>> = props => {
  const { children } = props

  const { theme } = useSelector((state: RootState) => state.app)

  return (
    <ThemeProvider theme={themes[theme]}>
      <LayoutComponent>{children}</LayoutComponent>
    </ThemeProvider>
  )
}

export default Layout
