import { Theme, Typography, TypographyProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'gatsby'
import React, { FC } from 'react'
import { MenuLinkItem } from '../../../interfaces/common'

const useStyles = makeStyles((theme: Theme) => ({
  link: (props: { isBold?: boolean; isContent?: boolean }) => ({
    fontWeight: props.isBold ? 'bold' : 'normal',
    textDecoration: 'none',
    color: props.isContent ? theme.palette.primary.main : '#4F4F4F',
    '&:hover': {
      color: props.isContent
        ? theme.palette.primary.dark
        : theme.palette.primary.main,
    },
  }),
}))

export interface SimpleLinkProps {
  item: MenuLinkItem
  isBold?: boolean
  isContent?: boolean
  handleClick?(): void
}

const SimpleLink: FC<SimpleLinkProps & TypographyProps> = props => {
  const { item, isBold, isContent, handleClick } = props
  const classes = useStyles({ isBold, isContent })

  return (
    <Typography variant={props.variant || 'subtitle1'}>
      <Link to={item.path} className={classes.link} onClick={handleClick}>
        {item.label}
      </Link>
    </Typography>
  )
}

export default SimpleLink
