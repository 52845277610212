import CONFIG from '../config'
import { FORBIDEN_MAIL, MAIL_LINK_ROOT } from '../constants/mail'
import { MailLinkTypeEnum } from '../enum/mail'
import {
  CreateCompanyMailPayload,
  MailSendPayload,
  UpdateFolderMailPayload,
} from '../interfaces/mail'

export const generateLink = (linkType: string, code: string): string =>
  `${MAIL_LINK_ROOT}?${linkType}=${code}`

export const mailPayloadCreateCompany = (
  mailPayload: CreateCompanyMailPayload,
): MailSendPayload => {
  const {
    isForUser,
    code,
    folderNumber,
    destinations,
    date,
    companyIdentity,
  } = mailPayload
  const companyName = companyIdentity.name || ''

  const linkType = isForUser ? MailLinkTypeEnum.EDIT : MailLinkTypeEnum.READ
  const generatedLink = generateLink(linkType, code)
  const htmlMessage = isForUser
    ? `
    <p>
      Bonjour,
      <br/>
      Nous avons bien reçu votre soumission de dossier pour une création d’entreprise via le site <a href="https://orinasa.edbm.mg">orinasa.edbm.mg</a>.
    </p>
      <p>Votre dossier a bien été enregistré le ${date}.
      <br/>
      Numéro : <b>${folderNumber}</b>
    </p>
    <p>
      Vous pouvez mettre à jour les données saisies ou envoyer de nouveaux documents en cliquant <a href="${generatedLink}">ici</a>.
    </p>

    <p>
      Vous aurez un retour dans un délai estimatif de 24h selon les heures ouvrées du guichet unique de l’EDBM : du lundi au vendredi de 8h à 17h.
    </p>

    <p>
      L’équipe du Guichet Unique,<br/>
      Economic Development Board Of Madagascar<br/>
      Immeuble EDBM, Avenue Gal Gabriel RAMANANTSOA Antaninarenina<br/>
      ANTANANARIVO MADAGASCAR<br/>
      Mail : <a href="mailto:${CONFIG.mail.contact}">orinasa@edbm.mg</a>
    </p>
    `
    : `
    <h1>Nouveau dossier créé!</h1>
    <p>Un nouveau dossier a été créé, numéro :<b>${folderNumber}</b></p>
    <p>Cliquer <a href="${generatedLink}">ici</a> pour consulter.</p>
    `
  const emailPayload: MailSendPayload = {
    mailTo: destinations,
    subject: `Création d’entreprise : Confirmation de réception de dossier – Société: ${companyName} – Numéro dossier : ${folderNumber}`,
    htmlMessage,
  }

  return emailPayload
}

export const mailPayloadUpdateFolder = (
  mailPayload: UpdateFolderMailPayload,
): MailSendPayload => {
  const { folderNumber, destinations, companyIdentity } = mailPayload
  const companyName = companyIdentity.name || ''

  const htmlMessage = `
    <p>
      Bonjour,
      <br/>
      Nous avons bien reçu les nouvelles données concernant votre dossier de création d’entreprise numéro  : <b>${folderNumber}</b>.
    </p>
    <p>
      Ces informations annulent et remplacent celles que vous avez précédemment renseignées. Le délai de traitement de votre dossier peut être impacté par ces modifications.
    </p>
    <p>
      Vous serez notifiés dès confirmation de la recevabilité de votre dossier.
    </p>

    <p>
      L’équipe du Guichet Unique,<br/>
      Economic Development Board Of Madagascar<br/>
      Immeuble EDBM, Avenue Gal Gabriel RAMANANTSOA Antaninarenina<br/>
      ANTANANARIVO MADAGASCAR<br/>
      Mail : <a href="mailto:${CONFIG.mail.contact}">orinasa@edbm.mg</a>
    </p>
    `

  const emailPayload: MailSendPayload = {
    mailTo: destinations,
    subject: `Création d’entreprise : Confirmation de modification de vos données – Société: ${companyName} – Numéro dossier : ${folderNumber}`,
    htmlMessage,
  }

  return emailPayload
}

export const mailToAgentPayloadUpdateFolder = (
  mailPayload: UpdateFolderMailPayload,
): MailSendPayload => {
  const { folderNumber, destinations, companyIdentity } = mailPayload
  const companyName = companyIdentity.name || ''

  const htmlMessage = `
    <p>
      Bonjour,
      <br/>
      Le dossier portant le numéro : <b>${folderNumber}</b> a été mis à jour par le remetteur.
    </p>
    <p>
      Nous vous invitons à traiter ce dossier.
    </p>

    <p>
      L’équipe du Guichet Unique,<br/>
      Economic Development Board Of Madagascar<br/>
      Immeuble EDBM, Avenue Gal Gabriel RAMANANTSOA Antaninarenina<br/>
      ANTANANARIVO MADAGASCAR<br/>
      Mail : <a href="mailto:${CONFIG.mail.contact}">orinasa@edbm.mg</a>
    </p>
    `

  const emailPayload: MailSendPayload = {
    mailTo: destinations,
    subject: `Création d’entreprise : Dossier MAJ – Société: ${companyName} – Numéro dossier : ${folderNumber}`,
    htmlMessage,
  }

  return emailPayload
}

export const isForbidenMail = (mail: string): boolean => {
  let isForbiden = true
  const decompose = mail.split('@')
  if (decompose.length > 1) {
    const domain = decompose[decompose.length - 1]
    return FORBIDEN_MAIL.includes(domain)
  }

  return isForbiden
}

export const mailPayloadNoDocFolder = (
  mailPayload: UpdateFolderMailPayload,
): MailSendPayload => {
  const { folderNumber, destinations, companyIdentity } = mailPayload
  const companyName = companyIdentity.name || ''

  const htmlMessage = `
    <p>
      Bonjour,
      <br/>
      Nous avons bien reçu votre soumission de dossier pour une création d’entreprise via le site <a href="https://orinasa.edbm.mg">orinasa.edbm.mg</a>.
    </p>
    <p>
      Nous ne pouvons effectuer l’étude de recevabilité de votre dossier numéro ${folderNumber} car <b>aucun document justificatif n'a été fournit</b>.
    </p>
    <p>
      Nous vous prions de compléter votre dossier en transférant les documents nécessaires. Vous pouvez utiliser le <b>lien envoyé dans le mail de confirmation</b> de réception de votre dossier pour accéder à nouveau au formulaire et à l'interface de transfert avec la liste des documents attendus.
    </p>
    <p>
      Nous vous remercions de votre compréhension et de votre collaboration.
    </p>

    <p>
      L’équipe du Guichet Unique,<br/>
      Economic Development Board Of Madagascar<br/>
      Immeuble EDBM, Avenue Gal Gabriel RAMANANTSOA Antaninarenina<br/>
      ANTANANARIVO MADAGASCAR<br/>
      Mail : <a href="mailto:${CONFIG.mail.contact}">orinasa@edbm.mg</a>
    </p>
  `

  const emailPayload: MailSendPayload = {
    mailTo: destinations,
    subject: `Création d’entreprise : DOSSIER INCOMPLET – Société: ${companyName} – Numéro dossier : ${folderNumber}`,
    htmlMessage,
  }

  return emailPayload
}
