import { graphql, useStaticQuery } from 'gatsby'

export interface HeaderData {
  id: string
  site_name_mg: string
  site_name_fr: string
  site_name_en: string
  ressource_mg: string
  ressource_fr: string
  ressource_en: string
  home_mg: string
  home_fr: string
  home_en: string
}

export default (): HeaderData => {
  const data = useStaticQuery(graphql`
    {
      strapiHeader {
        id
        header {
          id
          site_name_mg
          site_name_fr
          site_name_en
          ressource_mg
          ressource_fr
          ressource_en
          home_mg
          home_fr
          home_en
          connexion_en
          connexion_fr
          connexion_mg
          signup_mg
          signup_fr
          signup_en
        }
      }
    }
  `)

  return data.strapiHeader.header[0]
}
