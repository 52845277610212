import deepMerge from 'deepmerge'
import { red } from '@material-ui/core/colors'
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeOptions,
  Theme,
} from '@material-ui/core/styles'

import AeonikFont from './fonts/Aeonik/Aeonik-Regular.woff'

const aeonik = {
  fontFamily: 'Aeonik',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Aeonik'),
    local('Aeonik-Regular'),
    url(${AeonikFont}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const makeTheme = (variant: ThemeOptions): Theme => {
  const common: ThemeOptions = {
    palette: {
      primary: {
        main: '#179D75',
        contrastText: '#72E7A3',
      },
      secondary: {
        main: '#19857b',
      },
      error: {
        main: red.A400,
      },
      background: {
        default: 'white',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'none',
          textAlign: 'center',
        },
        contained: {
          boxShadow: '0px',
        },
        label: {
          color: 'white',
        },
      },
      MuiCssBaseline: {
        '@global': {
          '@font-face': [aeonik],
        },
      },
    },
    typography: {
      fontFamily: 'Aeonik',
      // h5: {
      //   fontWeight: 'bold',
      // },
      h6: {
        fontWeight: 'bold',
      },
      subtitle1: {
        fontFamily: 'sans-serif, Roboto',
      },
      subtitle2: {
        fontFamily: 'sans-serif, Roboto',
      },
      caption: {
        fontFamily: 'sans-serif, Roboto',
      },
      button: {
        fontFamily: 'sans-serif, Roboto',
      },
      body1: {
        fontFamily: 'sans-serif, Roboto',
        fontSize: '1.25rem',
      },
      body2: {
        fontFamily: 'sans-serif, Roboto',
        fontSize: '1.16rem',
      },
    },
  }

  const theme = createMuiTheme(deepMerge(common, variant))
  return responsiveFontSizes(theme)
}

const light: ThemeOptions = {
  palette: {
    type: 'light',
  },
}

const dark: ThemeOptions = {
  palette: {
    type: 'dark',
  },
}

const themes = {
  light: makeTheme(light),
  dark: makeTheme(dark),
}

export default themes
