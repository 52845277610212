import { graphql, useStaticQuery } from 'gatsby'
import { AdvertisingBannerNode } from '../../interfaces/advertising'

export default (): AdvertisingBannerNode[] => {
  const data = useStaticQuery(graphql`
    {
      allStrapiAdvertisingBanners(
        filter: {
          advertising: {
            elemMatch: { advertising_destination: { eq: "Header" } }
          }
        }
      ) {
        nodes {
          id
          advertising {
            id
            advertising_link
            advertising_destination
            advertising_images_desktop {
              childImageSharp {
                id
                fixed(width: 728, height: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            advertising_images_mobile {
              childImageSharp {
                id
                fixed(width: 320, height: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.allStrapiAdvertisingBanners.nodes
}
