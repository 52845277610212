// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-documents-legal-form-tsx": () => import("./../../../src/pages/documentsLegalForm.tsx" /* webpackChunkName: "component---src-pages-documents-legal-form-tsx" */),
  "component---src-pages-documents-list-tsx": () => import("./../../../src/pages/documentsList.tsx" /* webpackChunkName: "component---src-pages-documents-list-tsx" */),
  "component---src-pages-how-to-tsx": () => import("./../../../src/pages/howTo.tsx" /* webpackChunkName: "component---src-pages-how-to-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-status-choice-tsx": () => import("./../../../src/pages/legalStatusChoice.tsx" /* webpackChunkName: "component---src-pages-legal-status-choice-tsx" */),
  "component---src-pages-nomac-tsx": () => import("./../../../src/pages/nomac.tsx" /* webpackChunkName: "component---src-pages-nomac-tsx" */),
  "component---src-pages-regulated-activities-tsx": () => import("./../../../src/pages/regulatedActivities.tsx" /* webpackChunkName: "component---src-pages-regulated-activities-tsx" */),
  "component---src-pages-ressources-tsx": () => import("./../../../src/pages/ressources.tsx" /* webpackChunkName: "component---src-pages-ressources-tsx" */),
  "component---src-pages-simulation-fee-tsx": () => import("./../../../src/pages/simulationFee.tsx" /* webpackChunkName: "component---src-pages-simulation-fee-tsx" */),
  "component---src-pages-statistic-tsx": () => import("./../../../src/pages/statistic.tsx" /* webpackChunkName: "component---src-pages-statistic-tsx" */)
}

