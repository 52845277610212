import { Box, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import egmLogo from '../../../../images/logoEDBM.png'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '&:hover': {
      cursor: 'pointer',
    },
    '& img': {
      height: 37,
      marginTop: 10,
    },
  },
}))

const EDBMLogos = () => {
  const { container } = useStyles()

  const handleGoTo = () => {
    if (typeof window !== 'undefined') {
      window.open('https://edbm.mg/')
    }
  }

  return (
    <Box className={container} onClick={handleGoTo}>
      <img src={egmLogo} alt="egm-logo" height="30px" />
    </Box>
  )
}

export default EDBMLogos
