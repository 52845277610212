import { call, put, takeEvery } from 'redux-saga/effects'
import { MessageTypeEnum } from '../../enum/message'
import API from '../api'
import { sendMailLoading, SendMailSaga } from '../modules/mail'
import { displayMessage } from '../modules/message'

function* sendMailSE({ payload }: ReturnType<SendMailSaga>) {
  try {
    yield put(sendMailLoading(true))
    yield call(API.mail.sendMail, payload)
    yield put(
      displayMessage('Mail envoyé avec succès', MessageTypeEnum.SUCCESS),
    )
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(sendMailLoading(false))
  }
}

export function* sendMailWatcher() {
  yield takeEvery('MAIL/SEND_SAGA', sendMailSE)
}
