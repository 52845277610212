import { ActivityCategories } from '../hooks/nomac/useActivityCategories'
import { SelectItem } from '../interfaces/common'
import { getInternationalizedValue } from './common'

export const getActivityCode = (id: string, separation = '_'): string => {
  const splited = id.split(separation)
  if (splited.length > 1) {
    return splited[1]
  }
  return ''
}

export const getNomacOptions = (
  categories: ActivityCategories[],
  language = 'fr',
): SelectItem[] => {
  const formattedNomac: SelectItem[] = []
  categories.map(c => {
    const currentCode = getActivityCode(c.id)
    const description = getInternationalizedValue(
      c,
      'descriptivesCategorie',
      language,
    )
    const note = getInternationalizedValue(c, 'note', language)
    formattedNomac.push({
      label: `${currentCode} - ${description}`,
      value: currentCode,
    })
    if (note) {
      formattedNomac.push({
        label: `${currentCode} - ${note}`,
        value: currentCode,
      })
    }
  })
  formattedNomac.unshift({ label: '', value: '' })
  return formattedNomac
}
