import {
  AdvertisingBannerImages,
  ImageAdvertisingItem,
} from '../interfaces/advertising'
import { AdvertisingBannerNode } from '../interfaces/advertising'

export const getAdvertisingImageItems = (
  data: AdvertisingBannerNode[],
): AdvertisingBannerImages => {
  const desktop: ImageAdvertisingItem[] = []
  const mobile: ImageAdvertisingItem[] = []
  data.forEach(d => {
    if (d.advertising) {
      d.advertising.forEach(a => {
        if (a.advertising_link) {
          if (a.advertising_images_desktop) {
            desktop.push({
              link: a.advertising_link,
              dataImage: a.advertising_images_desktop,
            })
          }
          if (a.advertising_images_mobile) {
            mobile.push({
              link: a.advertising_link,
              dataImage: a.advertising_images_mobile,
            })
          }
        }
      })
    }
  })
  return { desktop, mobile }
}
