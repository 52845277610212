import { call, put, takeEvery } from 'redux-saga/effects'
import { MessageTypeEnum } from '../../enum/message'
import { SelectItem } from '../../interfaces/common'
import { convertAddressDataToItem } from '../../utils/address'
import API from '../api'
import {
  AddressChangeSaga,
  LoadAddressSaga,
  LoadQuartierSaga,
  setProvinces,
  setSubAddresses,
} from '../modules/address'
import { displayGeneralLoading } from '../modules/loading'
import { displayMessage } from '../modules/message'
import { setSubmitFormAddress } from '../modules/submitForm'

function* getProvinceSE() {
  try {
    yield put(displayGeneralLoading(true))
    const data = yield call(API.address.getProvince)
    const formattedProvinces = convertAddressDataToItem(data.provinces.province)
    yield put(setProvinces(formattedProvinces))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* getProvinceWatcher() {
  yield takeEvery('ADDRESS/GET_PROVINCE_SAGA', getProvinceSE)
}

function* addressChangeSE({ payload }: ReturnType<AddressChangeSaga>) {
  try {
    yield put(displayGeneralLoading(true))

    const { addressToChange, fieldData } = payload
    const name = fieldData.name
    const value = fieldData.value as string
    let formattedData: SelectItem[] = []

    switch (name) {
      case 'province':
        // Note: when we change 'province', list to update is 'region'
        const regionsData = yield call(API.address.getRegionByProvince, value)
        formattedData = convertAddressDataToItem(regionsData.regions.region)
        yield put(
          setSubAddresses({
            subAddressToChange: addressToChange,
            fieldName: 'region',
            fieldData: formattedData,
          }),
        )
        break
      case 'region':
        // Note: when we change 'region', list to update is 'district'
        const districtData = yield call(API.address.getDistrictByRegion, value)
        formattedData = convertAddressDataToItem(
          districtData.districts.district,
        )
        yield put(
          setSubAddresses({
            subAddressToChange: addressToChange,
            fieldName: 'district',
            fieldData: formattedData,
          }),
        )
        break
      case 'district':
        // Note: when we change 'district', list to update is 'commune'
        const communeData = yield call(API.address.getCommuneByDistrict, value)
        formattedData = convertAddressDataToItem(communeData.commons.common)
        yield put(
          setSubAddresses({
            subAddressToChange: addressToChange,
            fieldName: 'commune',
            fieldData: formattedData,
          }),
        )
        break
      case 'commune':
        // Note: when we change 'commune', list to update is 'arrondissement'
        const arrondissementData = yield call(
          API.address.getArrondissementByCommune,
          value,
        )
        formattedData = convertAddressDataToItem(
          arrondissementData.boroughs.borough,
        )
        yield put(
          setSubAddresses({
            subAddressToChange: addressToChange,
            fieldName: 'arrondissement',
            fieldData: formattedData,
          }),
        )
        break
      case 'arrondissement':
        // Note: when we change 'arrondissement', list to update is 'quartier'
        const quartierData = yield call(
          API.address.getQuartierByArrondissement,
          value,
        )
        formattedData = convertAddressDataToItem(
          quartierData.fokontanys.fokontany,
        )
        yield put(
          setSubAddresses({
            subAddressToChange: addressToChange,
            fieldName: 'quartier',
            fieldData: formattedData,
          }),
        )
        break
      default:
        break
    }
    // After getting data according to selected ID (province, region district, commune, arrondissement), update state and display subfield
    yield put(setSubmitFormAddress({ addressToChange, fieldData }))

    yield put(displayGeneralLoading(true))
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* addressChangeWatcher() {
  yield takeEvery('ADDRESS/CHANGE', addressChangeSE)
}

function* loadAddressSE({ payload }: ReturnType<LoadAddressSaga>) {
  try {
    yield put(displayGeneralLoading(true))

    const {
      addressToChange,
      data: { province, region, district, commune, arrondissement },
    } = payload

    // Get regions
    const regionsData = yield call(API.address.getRegionByProvince, province)
    const formattedRegion = convertAddressDataToItem(regionsData.regions.region)

    yield put(
      setSubAddresses({
        subAddressToChange: addressToChange,
        fieldName: 'region',
        fieldData: formattedRegion,
      }),
    )

    // Get districts
    const districtData = yield call(API.address.getDistrictByRegion, region)
    const formattedDistrict = convertAddressDataToItem(
      districtData.districts.district,
    )

    yield put(
      setSubAddresses({
        subAddressToChange: addressToChange,
        fieldName: 'district',
        fieldData: formattedDistrict,
      }),
    )

    // Get communes
    const communeData = yield call(API.address.getCommuneByDistrict, district)
    const formattedCommune = convertAddressDataToItem(
      communeData.commons.common,
    )

    yield put(
      setSubAddresses({
        subAddressToChange: addressToChange,
        fieldName: 'commune',
        fieldData: formattedCommune,
      }),
    )

    // Get arrondissement
    const arrondissementData = yield call(
      API.address.getArrondissementByCommune,
      commune,
    )
    const formattedArrondissement = convertAddressDataToItem(
      arrondissementData.boroughs.borough,
    )

    yield put(
      setSubAddresses({
        subAddressToChange: addressToChange,
        fieldName: 'arrondissement',
        fieldData: formattedArrondissement,
      }),
    )

    // Get quartier
    const quartierData = yield call(
      API.address.getQuartierByArrondissement,
      arrondissement,
    )
    const formattedQuartier = convertAddressDataToItem(
      quartierData.fokontanys.fokontany,
    )

    yield put(
      setSubAddresses({
        subAddressToChange: addressToChange,
        fieldName: 'quartier',
        fieldData: formattedQuartier,
      }),
    )
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* loadAddressWatcher() {
  yield takeEvery('ADDRESS/LOAD', loadAddressSE)
}

function* loadQuartierSE({ payload }: ReturnType<LoadQuartierSaga>) {
  try {
    yield put(displayGeneralLoading(true))

    const {
      addressToChange,
      data: { arrondissement },
    } = payload

    // Get quartier
    const quartierData = yield call(
      API.address.getQuartierByArrondissement,
      arrondissement,
    )
    const formattedQuartier = convertAddressDataToItem(
      quartierData.fokontanys.fokontany,
    )

    yield put(
      setSubAddresses({
        subAddressToChange: addressToChange,
        fieldName: 'quartier',
        fieldData: formattedQuartier,
      }),
    )
  } catch (e) {
    yield put(displayMessage(e.message, MessageTypeEnum.ERROR))
  } finally {
    yield put(displayGeneralLoading(false))
  }
}

export function* loadQuartierWatcher() {
  yield takeEvery('ADDRESS/LOAD_QUARTIER', loadQuartierSE)
}
