import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, { FC } from 'react'

interface CustomProgressProps {
  isDisplayed: boolean
}

const CustomProgress: FC<CustomProgressProps> = props => (
  <>
    {props.isDisplayed ? (
      <Box position="fixed" top="45%" left="45%" zIndex={999999}>
        <CircularProgress size={100} thickness={1} />
      </Box>
    ) : null}
  </>
)

export default CustomProgress
