import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import React, { ReactNode } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import CustomProgress from '../components/common/customProgress'
import GlobalSnackbar from '../components/common/globalSnackbar'
import Layout from '../layout'
import themes from '../theme'

const wrapPageElement = ({ element }: { element: ReactNode }) => {
  return (
    <ThemeProvider theme={themes['light']}>
      <CssBaseline />
      <GlobalSnackbar />
      <CustomProgress />
      <Layout>{element}</Layout>
    </ThemeProvider>
  )
}

export default wrapPageElement
