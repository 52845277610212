import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { AddressReducer } from './modules/address'
// import app from './modules/appModule'
import { AppReducer } from './modules/app'
import { FormDataReducer } from './modules/formData'
import { LoadingReducer } from './modules/loading'
import { MailReducer } from './modules/mail'
import { MessageReducer } from './modules/message'
import { NomacReducer } from './modules/nomac'
import { StatisticReducer } from './modules/statistic'
import { SubmitFormReducer } from './modules/submitForm'
import { loadState, saveState } from './persistStore'
import rootSaga from './saga'

const preloadedState = loadState()
const sagaMiddleware = createSagaMiddleware()
const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware]

const rootReducer = combineReducers({
  // app,
  app: AppReducer,
  nomac: NomacReducer,
  submitForm: SubmitFormReducer,
  address: AddressReducer,
  message: MessageReducer,
  loading: LoadingReducer,
  formData: FormDataReducer,
  mail: MailReducer,
  statistic: StatisticReducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware,
  preloadedState,
})

sagaMiddleware.run(rootSaga)

store.subscribe(() => {
  const { app } = store.getState()
  saveState({ app })
})

export type RootState = ReturnType<typeof rootReducer>
export type RootDispatch = typeof store.dispatch

export default store
