import { graphql, useStaticQuery } from 'gatsby'
import { ImagePublicUrl } from '../../interfaces/common'

export interface FooterData {
  id: string
  site_name_mg: string
  site_name_fr: string
  site_name_en: string
  right_mg: string
  right_fr: string
  right_en: string
  ressource_mg: string
  ressource_fr: string
  ressource_en: string
  legal_mention_mg: string
  legal_mention_fr: string
  legal_mention_en: string
  contact_us_mg: string
  contact_us_fr: string
  contact_us_en: string
  cgu_mg: string
  cgu_fr: string
  cgu_en: string
  about_mg: string
  about_fr: string
  about_en: string
  bg_image: ImagePublicUrl
  common_links: FooterLinkData[]
}

export interface FooterMediaData {
  domaine_name: string
  site_title: string
  id: string
  links: FooterLinkData[]
}

export interface FooterLinkData {
  link_value: string
  name_en: string
  name_fr: string
  name_mg: string
  id: string
}

export default (): { footer: FooterData; footer_media: FooterMediaData } => {
  const data = useStaticQuery(graphql`
    {
      strapiFooter {
        id
        footer {
          id
          site_name_mg
          site_name_fr
          site_name_en
          right_mg
          right_fr
          right_en
          ressource_mg
          ressource_fr
          ressource_en
          legal_mention_mg
          legal_mention_fr
          legal_mention_en
          contact_us_mg
          contact_us_fr
          contact_us_en
          cgu_mg
          cgu_fr
          cgu_en
          about_mg
          about_fr
          about_en
          bg_image {
            publicURL
          }
          common_links {
            id
            link_value
            name_en
            name_fr
            name_mg
          }
        }
        footer_media {
          domaine_name
          site_title
          links {
            id
            link_value
            name_en
            name_fr
            name_mg
          }
          id
        }
      }
    }
  `)

  return data.strapiFooter
}
